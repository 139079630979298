var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"title":_vm.pWorkflow.name,"breadcrumbs":_vm.pBreadcrumbs},scopedSlots:_vm._u([{key:"top-tabbar",fn:function(){return [_c('ViewWorkflowTopTabBar',{attrs:{"workflow":_vm.id,"selectedIndex":0}})]},proxy:true},{key:"quick-action-items",fn:function(){return [_c('div',{staticClass:"action-data mt-4"},[_c('WorkflowContactsQuickActions',{attrs:{"workflows":[_vm.pWorkflow]},on:{"onDeleteClick":_vm.onDeleteClick}})],1)]},proxy:true},{key:"global-menu-items",fn:function(){return [_c('WorkflowContactsQuickActions',{attrs:{"workflows":[_vm.pWorkflow]},on:{"onDeleteClick":_vm.onDeleteClick}})]},proxy:true}])},[_c('div',{staticClass:"mt-4"},[_c('b-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":_vm.$t('myTasks')}},[(_vm.activeTab === 0)?_c('ViewWorkflowTaskList',{attrs:{"filters":{
           workflow: _vm.id,
           status: 'pending',
           assignedTo: 'user'
          },"emptyMessage":_vm.$t('screen.workflows.task.me.empty.message')}}):_vm._e()],1),_c('b-tab-item',{attrs:{"label":_vm.$t('assignedTask')}},[(_vm.activeTab === 1)?_c('ViewWorkflowAssignedTaskList',{attrs:{"filters":{
            workflow: _vm.id,
            assignedTo: 'contact'
          },"emptyMessage":_vm.$t('screen.workflows.task.assigned.empty.message')}}):_vm._e()],1),_c('b-tab-item',{attrs:{"label":_vm.$t('completed')}},[(_vm.activeTab === 2)?_c('ViewWorkflowTaskList',{attrs:{"filters":{
            workflow: _vm.id,
            status: 'completed'
          },"emptyMessage":_vm.$t('screen.workflows.task.completed.empty.message')}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }