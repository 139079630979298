











































import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  CreateWorkflow,
} from '@/types';
import { actions } from '@/store';
import { min, max, required } from '@/utils/validations';

@Component
export default class CreateUpdateWorkflow extends BaseComponent {
  @Prop() btnText?: string;

  @Prop() formHandler?: Function;

  validators = {
    name: [
      required(this.$t('required.field', [this.$t('screen.workflows.create.name')])),
      max(120, this.$t('tooLong', [this.$t('screen.workflows.create.name')])),
    ],
    template: [
      required(this.$t('required.field', [this.$t('screen.workflows.create.template')])),
    ],
  };

  handleSubmit(values: any) {
    const payload: CreateWorkflow = {
      name: values.name,
      template: values.template,
    };

    if (this.formHandler) {
      this.formHandler(payload);
    }
  }

  mounted() {
    this.$store.dispatch(actions.TEMPLATE_GET_ALL);
  }
}
