



































































import { Component } from 'vue-property-decorator';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

import { actions } from '@/store';
import {
  CustomError,
  SubscriptionCreateSessionRequestPayload,
  SubscriptionCreateSessionResponse,
  SubscriptionPrice,
} from '@/types';

import { skipIdentityVerification } from '@/utils/env-helper';

import BaseComponent from '@/components/base-component';

@Component({
  components: {
    StripeCheckout,
  },
})
export default class Onfido extends BaseComponent {
  publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

  loading = false;

  prices: SubscriptionPrice[] = [];

  lineItems: object[] = [];

  sessionId = '';

  error?: CustomError | null;

  // eslint-disable-next-line class-methods-use-this
  get registrationStep() {
    return skipIdentityVerification() ? 2 : 3;
  }

  created() {
    this.loadData();
  }

  async loadData() {
    this.error = null;
    try {
      await this.loadPrices();
    } catch (error) {
      this.error = error;
    }
  }

  async loadPrices() {
    const prices = await this.$store.dispatch(actions.PAYMENT_GATEWAY_PRICES);

    prices.forEach((price: any) => {
      const item = price;

      item.selected = price.interval === 'year';

      if (item.selected) {
        this.lineItems = [{
          price: item.id,
          quantity: 1,
        }];
      }
    });

    this.prices = prices;
  }

  selectPrice(price: any) {
    this.prices = this.prices.map((item) => {
      if (price.id === item.id) {
        return {
          ...item,
          selected: true,
        };
      }

      const { selected, ...rest } = item;

      return rest;
    });

    this.lineItems = [{
      price: price.id,
      quantity: 1,
    }];
  }

  async checkout() {
    const result = await this.createSession();

    if (result) {
      (this.$refs.checkoutRef as any).redirectToCheckout();
    }
  }

  async createSession() {
    try {
      const payload: SubscriptionCreateSessionRequestPayload = {
        mode: 'subscription',
        lineItems: this.lineItems,
      };

      const data: SubscriptionCreateSessionResponse = await this.$store
        .dispatch(actions.PAYMENT_GATEWAY_CREATE_SESSION, payload);

      this.sessionId = data.id;

      return true;
    } catch (error) {
      this.handleError(error, 'form.subscription.error.title');
    }

    return false;
  }
}
