import { AxiosRequestConfig } from 'axios';

const getHeaders = () => ({
  'X-App-Id': process.env.VUE_APP_API_KEY,
  'X-App-Secret': process.env.VUE_APP_API_SECRET,
});

export default (config: AxiosRequestConfig): AxiosRequestConfig => {
  Object.assign(config.headers, getHeaders());

  return config;
};
