






















































































import { Component } from 'vue-property-decorator';

import { actions } from '@/store';
import BaseComponent from '../base-component';

@Component
export default class DashboardHeader extends BaseComponent {
  // isSearchVisible = true
  //
  // isTagVisible = true

  showSidebar() {
    this.$store.dispatch(actions.SIDEBAR_SHOW_ON_MOBILE);
  }

  // toggleTags() {
  //   this.isTagVisible = !this.isTagVisible;
  // }

  toggleSearch() {
    this.$router.push('/search');
    // this.isSearchVisible = !this.isSearchVisible;
    //
    // this.isTagVisible = false;
  }
}
