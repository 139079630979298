






















import { Component } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class FileManagerTopTabBar extends BaseComponent {

}
