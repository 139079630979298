




















import {
  Contact,
  defaultQuery,
  SortItem,
  PaginatedResult,
  emptyPaginatedResult,
} from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import { actions } from '@/store';
import Workflow from './Workflow';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:DESC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'dueDate:DESC',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'title:ASC',
    label: 'Title asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'title:DESC',
    label: 'Title desc',
    icon: 'sort-alphabetical-descending',
  },
];

@Component
export default class ViewWorkflowTaskList extends Workflow {
  private query = defaultQuery;

  result: PaginatedResult<string> = emptyPaginatedResult();

  dataFetched = false;

  sortItems = sortItems;

  selectedSort = sortItems[0];

  @Prop({ default: () => ({}) }) filters?: object;

  get pData() {
    const { groupedTasks } = this.$store.state.tasks;
    const results = groupedTasks.data.results
      .map((id: string) => this.$store.state.task.groupedData[id]);

    return {
      ...groupedTasks.data,
      results,
    };
  }

  onContactChange(contact: Contact) {
    let filters = {};

    if (contact.id) {
      filters = {
        contact: contact.id,
      };
    }

    this.loadData({
      page: 1,
      filters,
    });
  }

  onSortChange(item: SortItem) {
    let filters = {};

    this.selectedSort = item;

    filters = {
      ...filters,
      _sort: item.key,
    };

    this.loadData({
      page: 1,
      filters,
    });
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getTaskById(id: string) {
    return this.$store.getters['task/getById'](id);
  }

  mounted() {
    this.loadData();
  }

  async loadData(query: any = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
        filters: {
          ...this.filters,
          ...query.filters,
          status: JSON.stringify({ $ne: 'completed' }),
        },
      };

      this.result = await this.$store.dispatch(
        actions.ASSIGNED_TASK_LIST,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }
}
