










































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { compareDesc } from 'date-fns';

import BaseComponent from '@/components/base-component';
import layouts from '@/layouts';
import { actions } from '@/store';
import { Document, DocumentCommentPayload, Comment } from '@/types';

@Component
export default class ThirdPartyDocumentRequestHistory extends BaseComponent {
  @Prop({ default: false }) globalCheck?: boolean;

  showModal = false;

  showUploadModal = false;

  modalItem: any = {};

  get documents(): Document[] {
    const arr = this.$store.state.thirdParty.history.data?.documents || [];

    arr.sort((a: any, b: any) => {
      const date1 = a.sentAt || a.signedAt ? new Date(a.sentAt || a.signedAt) : new Date(0);
      const date2 = b.sentAt || b.signedAt ? new Date(b.sentAt || b.signedAt) : new Date(0);

      return compareDesc(date1, date2);
    });

    return arr;
  }

  get requestedBy() {
    const data = this.$store.state.thirdParty.history.data || {};
    const assignedBy = this.isBusiness ? 'created_by' : 'contact';
    return data[assignedBy] || {};
  }

  get assignedTo() {
    const data = this.$store.state.thirdParty.history.data || {};
    const assignedTo = this.isBusiness ? 'contact' : 'created_by';
    return data[assignedTo] || {};
  }

  get workflowName() {
    return this.$store.state.thirdParty.history.data?.workflow?.name || '';
  }

  get loading() {
    return this.$store.state.thirdParty.history.loading;
  }

  get comments() {
    const document: Document = this.$store.state.thirdParty.history.data.documents
      .find((doc: Document) => doc.id === this.modalItem.id);

    const comments = document?.comments || [];

    return [...comments].sort(this.sortComments);
  }

  sortComments = (a: Comment, b: Comment) => {
    if (a.postedAt > b.postedAt) {
      return -1;
    }

    if (a.postedAt < b.postedAt) {
      return 1;
    }

    return 0;
  }

  get savingComment() {
    return this.$store.state.thirdParty.history.comment.saving;
  }

  // eslint-disable-next-line class-methods-use-this
  title() {
    return 'Request History';
  }

  created() {
    this.loadData();
  }

  async loadData() {
    if (!this.$store.state.thirdParty.history.isDataFetched) {
      const { token } = this.$route.params;

      if (token) {
        await this.$store.dispatch('thirdParty/history', token);

        this.$emit('onDataLoaded', this.$store.state.thirdParty.history.data);
      }
    } else {
      this.$emit('onDataLoaded', this.$store.state.thirdParty.history.data);
    }
  }

  handleShowModal(document: Document) {
    this.modalItem = {
      ...document,
    };

    this.loadComments();

    this.showModal = true;
  }

  handleCloseModal() {
    this.showModal = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.modalItem = {} as any;
  }

  onModalClose(closed: boolean) {
    this.showModal = closed;
  }

  handleShowUploadModal(document: Document) {
    this.modalItem = {
      ...document,
    };

    this.showUploadModal = true;
  }

  handleCloseUploadModal() {
    this.showUploadModal = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.modalItem = {} as any;
  }

  handleModalSave() {
    const document = {
      ...this.modalItem,
      selected: !!this.modalItem.attachments?.length,
    };

    this.$store.commit('thirdParty/mutateHistoryObject', document);
    this.showUploadModal = false;
  }

  onUploadModalClose(closed: boolean) {
    this.showUploadModal = closed;
  }

  loadComments() {
    const { token } = this.$route.params;
    const { id } = this.modalItem;

    this.$store.dispatch(actions.THIRD_PARTY_DOCUMENT_COMMENTS, {
      token,
      id,
    });
  }

  async postComment() {
    const { token } = this.$route.params;
    const payload: DocumentCommentPayload = {
      documentId: this.modalItem.id,
      comment: this.modalItem.comment,
    };

    await this.$store.dispatch(actions.THIRD_PARTY_POST_DOCUMENT_COMMENT, {
      token,
      payload,
    });

    this.modalItem.comment = '';
  }

  // handleModalSave() {
  //   this.$store.commit('thirdParty/mutateHistoryObject', this.modalItem);
  //   this.showModal = false;
  // }

  validate() {
    const count = this.documents.filter((doc) => doc.selected)
      .reduce((prev: number, current: Document) => prev + (current.attachments?.length || 0), 0);

    if (!count) {
      this.handleError({
        detail: {
          code: 'form.thirdParty.document.history.payload.empty.message',
        },
      }, 'form.thirdParty.document.history.error.title');
      return false;
    }

    return true;
  }

  async handleSubmit() {
    let loadingComponent;
    try {
      if (!this.validate()) {
        return;
      }

      const { token } = this.$route.params;

      loadingComponent = this.$buefy.loading.open({
        isFullPage: true,
        canCancel: false,
      });

      await this.$store.dispatch('thirdParty/updateRequest', token);

      this.notify({
        type: 'is-success',
        message: this.$t('form.thirdParty.document.history.success.message') as string,
      });

      if (loadingComponent) {
        loadingComponent.close();
      }

      await this.$store.dispatch('thirdParty/history', token);
    } catch (error) {
      if (loadingComponent) {
        loadingComponent.close();
      }

      this.handleError(error, 'form.thirdParty.document.history.error.title');
    }
  }

  handleNewRequest() {
    if (this.$route.meta.layout === layouts.DashboardLayout) {
      this.$router.push({ name: 'AHDocumentNewRequest' });
    } else {
      this.$router.push({ name: 'ThirdPartyDocumentNewRequest' });
    }
  }

  checkChanged() {
    const selectedCount = this.documents.filter((doc) => doc.selected).length;

    this.globalCheck = this.documents.length === selectedCount;
  }

  toggleGlobalCheck(checked: boolean) {
    this.globalCheck = checked;
    this.$store.commit('thirdParty/checkHistoryAll', checked);
  }
}
