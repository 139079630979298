


























































































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';

@Component
export default class PrivacyPolicy extends BaseComponent { }
