import axios, { AxiosInstance, AxiosResponse } from 'axios';

import interceptors from './interceptors';

const {
  applicationIdentity,
  jwtToken,
  checksum,
  formData,
} = interceptors;

const createInstance = () => axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  headers: { 'Content-Type': 'application/json' },
});

const addInterceptors = (instance: AxiosInstance, { jwt = true } = {}) => {
  // Add a request interceptors
  instance.interceptors.request.use(applicationIdentity);

  if (jwt) {
    instance.interceptors.request.use(jwtToken);
  }

  instance.interceptors.request.use(checksum.addHeader);
  instance.interceptors.request.use(formData.addHeader);

  // Add a response interceptor
  instance.interceptors.response.use((response) => response, interceptors.responseError);
};

const jwtApi = createInstance();
const api = createInstance();

addInterceptors(jwtApi);
addInterceptors(api, { jwt: false });

export default api;

export {
  api,
  jwtApi,
};
