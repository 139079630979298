

























import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import {
  SubscriptionCreateSessionResponse,
} from '@/types';

@Component
export default class Settings extends BaseComponent {
  // eslint-disable-next-line class-methods-use-this
  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.settings',
      },
    ];
  }

  async handleSubscriptionClick(e: Event) {
    e.preventDefault();

    const ref = this.$buefy.loading.open({
      isFullPage: true,
    });

    try {
      const data: SubscriptionCreateSessionResponse = await this.$store
        .dispatch(actions.PAYMENT_GATEWAY_CREATE_BILLING_SESSION);

      window.location.href = data.url;
    } catch (error) {
      this.handleError(error, 'form.subscription.error.title');
    }

    ref.close();
  }
}
