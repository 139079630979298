import { ActionTree } from 'vuex';

import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  Module,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '@/types';
import { Auth } from '@/api';
import { AxiosResponse } from 'axios';
import { RootState, PasswordState } from '../states';

const getDefaultState = (): PasswordState => ({
  loading: false,
  data: null,
  error: null,
});

const getters = {};

const actions: ActionTree<PasswordState, RootState> = {
  async forgot({ commit }: any, payload: ForgotPasswordRequest):
    Promise<ForgotPasswordResponse | any> {
    commit('setLoading', true);

    return new Promise<ForgotPasswordResponse | any>((resolve, reject) => {
      Auth.forgotPassword(payload)
        .then((resp: AxiosResponse<ForgotPasswordResponse>) => {
          resolve(resp.data);
        })
        .catch((error) => reject(error))
        .finally(() => commit('setLoading', false));
    });
  },
  async reset({ commit }: any, payload: ResetPasswordRequest):
    Promise<ResetPasswordResponse | any> {
    commit('setLoading', true);

    return new Promise<ResetPasswordResponse | any>((resolve, reject) => {
      Auth.resetPassword(payload)
        .then((resp: AxiosResponse<ResetPasswordResponse>) => {
          resolve(resp.data);
        })
        .catch((error) => reject(error))
        .finally(() => commit('setLoading', false));
    });
  },
};

const mutations = {
  setLoading(state: PasswordState, loading: boolean) {
    state.loading = loading;
  },
  reset(state: PasswordState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): PasswordState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const password: Module<PasswordState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
