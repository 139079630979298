import { AxiosResponse } from 'axios';

import {
  Contact,
  CreateContact,
  PaginatedResult,
  Query,
  Workflow,
} from '@/types';

import {
  jwtApi,
} from './api_base';
import { buildSearchQuery } from './utils';

export default class {
  static search(query: Query): Promise<AxiosResponse<PaginatedResult<Contact>>> {
    return jwtApi.get<PaginatedResult<Contact>>(`/contacts${buildSearchQuery(query)}`);
  }

  static getById(id: string): Promise<AxiosResponse<Contact>> {
    return jwtApi.get<Contact>(`/contacts/${id}`);
  }

  static workflows(id: string, query: Query):
  Promise<AxiosResponse<Workflow[]>> {
    const filters = buildSearchQuery(query);
    return jwtApi.get<Workflow[]>(`/contacts/${id}/workflows${filters}`);
  }

  static multiple(ids: string[]): Promise<AxiosResponse<Contact[]>> {
    return jwtApi.get<Contact[]>(`/contacts/ids?${ids.map((id) => `contacts=${id}`).join('&')}`);
  }

  static create(payload: CreateContact): Promise<AxiosResponse<Contact>> {
    return jwtApi.post<Contact>('/contacts', payload);
  }

  static update(id: string, payload: CreateContact): Promise<AxiosResponse<Contact>> {
    return jwtApi.put<Contact>(`/contacts/${id}`, payload);
  }

  static delete(id: string): Promise<AxiosResponse<Contact>> {
    return jwtApi.delete<Contact>(`/contacts/${id}`);
  }
}
