



































































































































































































































































































import ms from 'ms';
import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';

import {
  Comment,
  Action,
  Document,
  DocumentCommentPayload,
  DocumentStatusPayload,
  File,
  Workflow,
  WorkflowFile,
  ESignRequestPayload,
  ESign,
  User,
  DocumentStatus,
} from '@/types';
import { fileUtils, helloSign } from '@/utils';

import BaseComponent from '../base-component';

@Component
export default class WorkflowDocumentDetail extends BaseComponent {
  eSignCode = process.env.VUE_APP_ESIGN_ACTION_CODE;

  comment = '';

  attachments: File[] = [];

  statusId = '';

  documentId = '';

  documentName?: string | null = null;

  status = '';

  @Prop({ default: false }) loading?: boolean;

  @Prop() document?: Document;

  get pLoading() {
    return this.loading;
  }

  get pDocument() {
    this.status = this.status || (this.document?.status as DocumentStatus)?.code;

    if (this.documentName === undefined || this.documentName === null) {
      this.documentName = this.document?.documentName;
    }

    return this.document;
  }

  get assignedTo() {
    const document = this.document as any;
    return document[document.assignedTo as string] || {};
  }

  get requestedBy() {
    const document = this.document as any;
    return document[document.assignedBy as string] || {};
  }

  get workflowName() {
    return (this.document?.workflow as Workflow)?.name || '';
  }

  get eSign() {
    return !this.document?.signed
      && (this.document?.action as Action).code === process.env.VUE_APP_ESIGN_ACTION_CODE;
  }

  get eSignEnabled() {
    const file = (this.document?.files as any[])[0];

    const duration = ms(process.env.VUE_APP_ESIGN_LOCK_DURATION);
    if (file.status === 'signing' && new Date(file.statusUpdatedAt).getTime() + duration > Date.now()) {
      return false;
    }

    return true;
  }

  get contactFiles() {
    return (this.document?.files as any[] || []).filter((f: any) => f.uploadedBy === 'contact');
  }

  get myFiles() {
    return (this.document?.files as any[] || []).filter((f: any) => f.uploadedBy === 'user');
  }

  get comments() {
    const comments = this.document?.comments as any[] || [];
    return comments.map((comment) => comment).sort(this.sortComments);
  }

  get isDocumentOwner() {
    return this.pDocument?.assignedBy === 'user'
            && (this.pDocument?.user as User).id === this.$store.state.auth.identity?.id;
  }

  get catagory() {
    return this.$store.state.categories?.data[this.pDocument?.category as string] || {};
  }

  get documentNameUnchanged() {
    return this.documentName?.trim()?.toLowerCase() === this.pDocument?.documentName?.toLowerCase();
  }

  get disableSave() {
    const documentId = (this.pDocument?.document as any)?.id as string;
    const statusId = (this.pDocument?.status as DocumentStatus).id as string;
    this.statusId = this.statusId
      ? this.statusId : statusId;
    this.documentId = this.documentId
      ? this.documentId : documentId;
    return this.documentId === documentId
      && (this.documentName && this.documentNameUnchanged)
      && this.statusId === statusId && !this.attachments.length;
  }

  sortComments = (a: Comment, b: Comment) => {
    if (a.postedAt > b.postedAt) {
      return -1;
    }

    if (a.postedAt < b.postedAt) {
      return 1;
    }

    return 0;
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.$store.dispatch('categories/list');
  }

  onStatusChange(status: DocumentStatus) {
    this.$store.commit('workflowDocuments/setDetailData', {
      ...this.document,
      status,
    });
  }

  selectFile(fileSelected: File) {
    if (fileSelected) {
      let path = fileSelected.attributes.find((attribute) => attribute.name === 'target')?.value;

      if (!path) {
        path = fileSelected.pathLower;
      }

      let exist = this.attachments.find((file) => {
        let p = file.attributes.find((attribute) => attribute.name === 'target')?.value;

        if (!p) {
          p = file.pathLower;
        }

        return p === path;
      });

      if (!exist) {
        exist = (this.document?.files as any[] || []).find((f) => {
          const file = f.file as File;
          let p = file.attributes.find((attribute) => attribute.name === 'target')?.value;

          if (!p) {
            p = file.pathLower;
          }

          return p === path;
        });
      }

      if (!exist) {
        this.attachments.push(fileSelected);

        return;
      }

      this.notify({
        type: 'is-warning',
        message: 'This file is already in the list hence cannot be added.',
      });
    }
  }

  getFileName = (file: File) => fileUtils.parseFileName(file)

  getFilePath = (file: File) => fileUtils.parseFilePath(file)

  deleteAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  handleDownloadClick = (e: Event) => {
    const element = e.currentTarget as Element;
    const path = element.getAttribute('path') as string;

    (element as any).href = fileUtils.buildDownloadUrl(path);
  }

  get savingComment() {
    return this.$store.state.workflowDocuments.comment.saving;
  }

  get savingDocument() {
    return this.$store.state.workflowDocuments.detail.saving;
  }

  deleteFile(file: any) {
    this.$buefy.dialog.confirm({
      title: 'Delete?',
      message: 'Are you sure you want to delete this file?',
      onConfirm: (_, __) => this.onDeleteFile(file.id),
    });
  }

  async onDeleteFile(id: string) {
    let loadingComponent: any = null;

    try {
      loadingComponent = this.$buefy.loading.open({
        isFullPage: true,
      });

      await this.$store.dispatch(actions.WORKFLOW_DELETE_FILE, id);

      this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_DETAIL, {
        id: (this.pDocument?.workflow as Workflow)?.id,
        documentId: this.pDocument?.id,
      });
    } catch (error) {
      this.handleError(error, 'deleteError');
    }

    if (loadingComponent) {
      loadingComponent.close();
    }
  }

  async postComment() {
    try {
      const payload: DocumentCommentPayload = {
        id: (this.document?.workflow as Workflow).id,
        documentId: this.document?.id as string,
        comment: this.comment,
      };

      await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_POST_COMMENT, payload);

      this.comment = '';
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.request.save.error.title');
    }
  }

  async changeStatus() {
    try {
      const payload: DocumentStatusPayload = {
        id: (this.document?.workflow as Workflow).id,
        documentId: this.document?.id as string,
        status: process.env.VUE_APP_COMPLETED_STATUS_CODE as string,
      };

      await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_POST_STATUS, payload);

      this.$router.replace({
        name: 'ViewWorkflowDocuments',
        params: { id: (this.document?.workflow as Workflow).id as string },
      });
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.request.save.error.title');
    }
  }

  async handleSave() {
    try {
      const workflow = (this.pDocument?.workflow as Workflow)?.id;
      const id = this.pDocument?.id as string;
      const document = (this.pDocument?.document as any)?.id as string;
      const status = (this.pDocument?.status as DocumentStatus)?.id as string;

      const payload: any = {
        id,
        workflow,
        paths: this.attachments.map((attachment) => {
          let path = attachment.attributes.find((attribute) => attribute.name === 'target')?.value;

          if (!path) {
            path = attachment.pathLower;
          }

          return path;
        }),
      };

      if (this.statusId !== status) payload.status = status;
      if (this.documentId !== document) payload.document = document;

      if (this.documentName && !this.documentNameUnchanged) {
        payload.documentName = this.documentName?.trim();
      }

      await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_UPDATE, payload);

      this.$router.replace({ name: 'ViewWorkflowDocuments', params: { id: (this.document?.workflow as Workflow).id as string } });
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.request.save.error.title');
    }
  }

  handleESignClick() {
    if (this.eSignEnabled) {
      const file = (this.document?.files as any[])[0];

      this.$store.commit('workflowDocuments/setESignFileStatus', {
        workflow: (this.document?.workflow as Workflow).id,
        document: this.document?.id as string,
        file: file.id,
      });

      this.handleESign(file);
    }
  }

  async handleESign(file: WorkflowFile) {
    try {
      const payload: ESignRequestPayload = {
        id: file.id as string,
      };

      const data: ESign = await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_ESIGN, payload);

      helloSign.client.open(data.signingUrl);
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.eSign.error.title');
    }
  }

  showAssignDocuments() {
    (this.$refs.fileActions as any).onClickAssign();
  }

  showUploadDocuments() {
    (this.$refs.fileActions as any).onClickUpload();
  }

  handleDocumentChange(documentId: string) {
    const document = this.$store.state.documentTypes.data[documentId];
    this.$store.commit('workflowDocuments/setDetailData', {
      ...this.document,
      document,
    });
  }
}
