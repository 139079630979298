








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {
  @Prop({ default: '/' }) to?: string;

  get pTo() {
    return this.to;
  }
}
