













































































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { BreadcrumbItem, Pagination } from '@/types';

@Component
export default class PendingContact extends BaseComponent {
  @Prop({ default: '' }) title?: string

  @Prop({ default: () => [] }) breadcrumbs?: BreadcrumbItem[];

  @Prop({ default: () => ({}) }) pagination?: Pagination;

  get pTitle() {
    return this.title;
  }

  get pBreadcrumbs() {
    return this.breadcrumbs;
  }

  get pPagination() {
    return this.pagination;
  }

  @Emit('onPageChange')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // eslint-disable-next-line class-methods-use-this
  onPageChange() {
    // ignore
  }

  navigateBack() {
    this.$router.back();
  }
}
