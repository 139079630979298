









import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

import {
  BreadcrumbItem,
  CreateUpdateTaskPayload,
} from '@/types';
import { actions } from '@/store';

@Component
export default class CreateTask extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.tasks',
      path: '/tasks',
    },
    {
      title: 'createTask',
    },
  ];

  formHandler(payload: CreateUpdateTaskPayload) {
    this.$store.dispatch(actions.TASK_CREATE, payload)
      .then(() => {
        this.notify({
          message: this.$t('screen.tasks.create.success.message') as string,
        });
        this.$router.replace('/tasks');
      })
      .catch((err) => {
        this.handleError(err, 'screen.tasks.create.error.title');
      });
  }
}
