


























import { Component } from 'vue-property-decorator';
import FileManagerComponent from '@/components/file-manager/file-manager';

@Component
export default class FileManagerSearch extends FileManagerComponent {
  get hasMore() {
    return this.$store.state.fileManager.hasMore;
  }

  onClickViewMore() {
    this.$store.dispatch('fileManager/search', this.$route.query.q)
      .catch((err) => this.handleError(err, 'error.generic'));
  }

  mounted() {
    if (!this.$route.query.q) {
      this.$router.replace('/dashboard');
      return;
    }
    this.onClickViewMore();
  }
}

