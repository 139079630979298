import { Component, Prop, Emit } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class InputComponent extends BaseComponent {
  @Prop({ default: false }) loading?: boolean;

  @Prop() protected name!: string;

  @Prop() protected type!: 'text' | 'password';

  @Prop() protected label!: string;

  @Prop() protected maxLength!: number;

  @Prop() protected validate!: Function | Function[];

  @Prop() protected placeholder!: string;

  @Prop({ default: false }) protected disabled?: boolean;

  @Prop({ default: 'on' }) autocomplete?: 'on' | 'off';

  private state: any;

  constructor() {
    super();

    this.$watch(this.name, this.changeValue, { deep: true });
  }

  get iconRight() {
    if (/* this.state.error && this.state.touched && */ this.state.value) {
      return 'close';
    }

    return '';
  }

  get pAutocomplete() {
    return this.autocomplete;
  }

  get fieldState() {
    return this.state;
  }

  iconRightClick(e: Event) {
    this.clearValue();
  }

  clearValue() {
    this.state.change('');
  }

  changeValue(newValue: any, oldValue: any) {
    this.state.change(newValue);
  }

  updateFieldState(state: any) {
    this.$data[this.name] = state.value;
    this.state = state;
  }

  disable() {
    this.disabled = true;
  }
}
