import { ActionTree } from 'vuex';

import {
  Module,
  CustomError,
  SubscriptionCreateSessionRequestPayload,
  SubscriptionCreateSessionResponse,
  SubscriptionPrice,
  Subscription,
} from '@/types';
import { PaymentGateway as api } from '@/api';
import { RootState, PaymentGatewayState } from '../states';

const getDefaultState = (): PaymentGatewayState => ({
  loading: false,
  error: null,
  prices: {
    loading: false,
    list: [],
    error: null,
  },
  session: {
    loading: false,
    data: null,
    creating: false,
    error: null,
  },
});

const getters = {
  prices: (state: PaymentGatewayState) => state.prices.list,
};

const actions: ActionTree<PaymentGatewayState, RootState> = {
  async prices({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SubscriptionPrice[]>(async (resolve, reject) => {
      commit('setPricesLoading', true);
      commit('setPricesError', null);

      try {
        const resp = await api.prices();
        const { data } = resp;

        commit('setPricesLoading', false);
        commit('setPrices', data);

        resolve(data);
      } catch (error) {
        commit('setPricesLoading', false);
        commit('setPricesError', error);

        reject(error);
      }
    });
  },
  async createSession({ commit }: any, payload: SubscriptionCreateSessionRequestPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SubscriptionCreateSessionResponse>(async (resolve, reject) => {
      commit('setSessionCreating', true);
      commit('setSessionCreationError', null);

      try {
        const resp = await api.createSession(payload);
        const { data } = resp;

        commit('setSessionCreating', false);
        commit('setSession', data);

        resolve(data);
      } catch (error) {
        commit('setSessionCreating', false);
        commit('setSessionCreationError', error);

        reject(error);
      }
    });
  },

  async createBillingSession({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SubscriptionCreateSessionResponse>(async (resolve, reject) => {
      commit('setSessionCreating', true);
      commit('setSessionCreationError', null);

      try {
        const resp = await api.createBillingSession();
        const { data } = resp;

        commit('setSessionCreating', false);
        commit('setSession', data);

        resolve(data);
      } catch (error) {
        commit('setSessionCreating', false);
        commit('setSessionCreationError', error);

        reject(error);
      }
    });
  },

  async subscription({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Subscription>(async (resolve, reject) => {
      try {
        const resp = await api.subscription();
        const { data } = resp;

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setPricesLoading(state: PaymentGatewayState, loading: boolean) {
    state.prices.loading = loading;
  },
  setPricesError(state: PaymentGatewayState, error: CustomError) {
    state.prices.error = error;
  },
  setPrices(state: PaymentGatewayState, list: SubscriptionPrice[]) {
    state.prices.list = list;
  },
  setSessionCreating(state: PaymentGatewayState, creating: boolean) {
    state.session.creating = creating;
  },
  setSessionCreationError(state: PaymentGatewayState, error: CustomError) {
    state.session.error = error;
  },
  setSession(state: PaymentGatewayState, data: SubscriptionCreateSessionResponse) {
    state.session.data = data;
  },
  reset(state: PaymentGatewayState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): PaymentGatewayState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const paymentGateway: Module<PaymentGatewayState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
