




























































































































































































































import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';

@Component
export default class Documents extends BaseComponent {
  isGrid= false;

  changeLayout() {
    this.isGrid = !this.isGrid;
  }

  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '',
    },
  ];
}

