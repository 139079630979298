






import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { User } from '@/types';

@Component
export default class SubscriptionSuccess extends BaseComponent {
  created() {
    this.loadUser();
  }

  async loadUser() {
    let user: User | null = null;

    try {
      user = await this.$store.dispatch(actions.USER_ME);
    } catch (error) {
      // ignore error
    }

    if (user?.subscription?.status === 'paid') {
      this.$router.replace({ name: 'Dashboard' });

      return;
    }

    setTimeout(() => {
      this.loadUser();
    }, 10000);
  }
}
