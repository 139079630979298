



















import { actions } from '@/store';
import { Workflow } from '@/types';
import { Component } from 'vue-property-decorator';
import { sortByDateDesc } from '@/utils/functions';

import WorkflowsComponent from './workflows';

@Component
export default class WorkflowSummaryList extends WorkflowsComponent {
  workflows: string[] = [];

  dataFetched = false;

  get pWorkflows() {
    const workflows = (this.workflows || []).map((id: string) => ({ ...this.getWorkflowById(id) }));
    return this.sortByDueDate(workflows);
  }

  // eslint-disable-next-line class-methods-use-this
  sortByDueDate(workflows: Array<Workflow>) {
    const updatedAtWorkflows = workflows.filter((item) => !item.dueDate);
    const dueWorkflows = workflows.filter((item) => item.dueDate);
    updatedAtWorkflows.sort((a, b) => sortByDateDesc('updatedAt')(a, b));
    dueWorkflows.sort((a, b) => sortByDateDesc('dueDate')(a, b));
    return [...dueWorkflows, ...updatedAtWorkflows];
  }

  getWorkflowById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  onActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'edit':
        this.onClickEdit(workflow);
        break;
      case 'delete':
        this.handleDelete(workflow);
        break;
      default:
      // do nothing
    }
  }

  onToggle(contact: Workflow, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }

  created() {
    this.loadData();
  }

  async loadData() {
    try {
      this.workflows = await this.$store.dispatch(actions.WORKFLOW_SUMMARY);
      await this.loadDetails(this.workflows);
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  workflowName = '';

  workflow: Workflow | null = null;

  onClickEdit(workflow: Workflow) {
    this.workflow = workflow;
    (this.$refs.workflowRenameModal as any).openModal(workflow.name);
  }

  handleRename(workflowName: string) {
    if (this.workflow) {
      this.$store.dispatch(actions.WORKFLOW_UPDATE, {
        id: this.workflow.id,
        name: workflowName,
      }).then(() => {
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  protected handleDelete(workflow: Workflow) {
    const workflowName = workflow.name;
    this.$buefy.dialog.confirm({
      title: this.$t('screen.workflows.delete.title') as string,
      message: (this.$t('screen.workflows.delete.singleDelete') as string).replace('{workflowName}', workflowName),
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('delete') as string,
      onConfirm: () => {
        this.$buefy.toast.open({
          message: this.$t('screen.workflows.delete.deleting') as string,
          position: 'is-bottom',
        });
        this.onDeleteClick([workflow]);
      },
    });
  }

  protected async onDeleteClick(workflows: Workflow[]) {
    try {
      await this.$store.dispatch(actions.WORKFLOW_DELETE, workflows[0].id);

      this.notify({
        message: this.$t('screen.workflows.delete.success.message') as string,
      });

      await this.loadData();
    } catch (error) {
      this.handleError(error, 'screen.workflows.delete.error.title');
    }
  }
}
