












































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import ChannelList from '@/components/ChannelList.vue'; // @ is an alias to /src

@Component({
  components: {
    ChannelList,
  },
})
export default class Home extends Vue {
  // eslint-disable-next-line class-methods-use-this
  mounted() {
    document.body.classList.add('home-page');
  }

  // eslint-disable-next-line class-methods-use-this
  destroyed() {
    document.body.classList.remove('home-page');
  }

  handleTryFreeClick() {
    this.$router.push({ name: 'SignUp' });
  }
}

