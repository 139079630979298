























import { Component } from 'vue-property-decorator';

import Contact from './Contact';

@Component
export default class ViewContactActivity extends Contact {}
