










































































































import ms from 'ms';
import { Component, Prop } from 'vue-property-decorator';

import { Action, Document, User } from '@/types';
import { formatDate } from '@/utils/functions';

import BaseComponent from '../base-component';

@Component
export default class WorkflowDocumentListItem extends BaseComponent {
  @Prop() document?: Document;

  get pDocument() {
    return this.document;
  }

  get eSign() {
    return !this.document?.signed
      && (this.document?.action as Action).code === process.env.VUE_APP_ESIGN_ACTION_CODE;
  }

  get eSignEnabled() {
    const file = (this.document?.files as any[])[0];

    const duration = ms(process.env.VUE_APP_ESIGN_LOCK_DURATION);
    if (file.status === 'signing' && new Date(file.statusUpdatedAt).getTime() + duration > Date.now()) {
      return false;
    }

    return true;
  }

  get isSigned() {
    return this.document?.signed;
  }

  get signedDesc() {
    return `Signed by you on ${formatDate(this.pDocument?.signedAt)}`;
  }

  get owner() {
    return this.pDocument?.assignedBy === 'user'
      && this.$store.state.auth.identity?.id
      && (this.pDocument?.user as User).id === this.$store.state.auth.identity?.id;
  }

  handleESignClick() {
    if (this.eSignEnabled) {
      const file = (this.document?.files as any[])[0];

      this.$store.commit('workflowDocuments/setESignFileStatus', {
        workflow: this.document?.workflow,
        document: this.document?.id,
        file: file.id,
      });

      this.$emit('onESignClick', (this.document?.files as any[])[0]);
    }
  }
}
