

































import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class WorkflowRenameModal extends BaseComponent {
  isModalActive = false;

  workflowName = '';

  workflowError = '';

  @Watch('isModalActive')
  onClickRename() {
    this.workflowError = '';
  }

  openModal(workflowName: string) {
    this.workflowName = workflowName;
    this.isModalActive = true;
  }

  closeModal() {
    this.workflowName = '';
    this.isModalActive = false;
  }

  handleRename() {
    this.workflowError = '';
    if (!this.workflowName || !this.workflowName.length) {
      this.workflowError = this.$t('screen.workflows.rename.error.required') as string;
      return;
    }
    if (this.workflowName.length > 120) {
      this.workflowError = this.$t('screen.workflows.rename.error.maxLength') as string;
      return;
    }
    this.$emit('onRename', this.workflowName);
  }
}
