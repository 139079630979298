import { AxiosResponse } from 'axios';
import qs from 'qs';

import storage from '@/storage';
import {
  ESign,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static getSignUrl(path: string): Promise<AxiosResponse<ESign>> {
    const { user } = storage;
    const { email, firstName, lastName } = user;
    const name = `${firstName} ${lastName}`;

    const queryString = qs.stringify({
      // 'app-id': process.env.VUE_APP_API_KEY,
      // 'app-secret': process.env.VUE_APP_API_SECRET,
      // token,
      path,
      email,
      name,
    });

    return jwtApi.get<ESign>(`/esign/sign-url?${queryString}`);
  }
}
