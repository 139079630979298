import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import { BreadcrumbItem, Task } from '@/types';
import Layout from '@/layouts/Layout';

const breadcrumbs: BreadcrumbItem[] = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
  },
  {
    title: 'dashboard.menu.tasks',
    path: '/tasks',
  },
];

@Component
export default class TaskComponent extends Layout {
  @Prop({ default: '' }) id?: string;

  get pTask(): Task {
    const task = this.$store.getters['task/getById'](this.id);

    return task || {
      id: this.id,
      title: '',
    };
  }

  get pBreadcrumbs() {
    let items = breadcrumbs;
    const task = this.$store.getters['task/getById'](this.id);

    if (task) {
      items = [
        ...items,
        {
          title: task.title,
        },
      ];
    }

    return items;
  }

  created() {
    const { id } = this.$route.params;

    this.id = id;

    if (id) {
      this.loadData();
    }
  }

  async loadData() {
    await this.$store.dispatch(actions.TASK_GET_BY_ID, this.id);
  }

  async onDeleteClick(tasks: Task[]) {
    try {
      await this.$store.dispatch(actions.CONTACT_DELETE, tasks[0].id);

      this.notify({
        message: this.$t('screen.tasks.delete.success.message') as string,
      });

      this.$router.back();
    } catch (error) {
      this.handleError(error, 'screen.tasks.delete.error.title');
    }
  }
}
