























import { Component, Prop } from 'vue-property-decorator';

import { DocumentStatus } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class ContactListDropdown extends BaseComponent {
  @Prop({ default: () => [] }) statuses?: DocumentStatus[];

  @Prop() selected?: DocumentStatus;

  get pStatuses() {
    return this.statuses;
  }

  get pSelected() {
    return this.selected;
  }

  set pSelected(status: DocumentStatus | undefined) {
    this.selected = status;
  }

  changeStatus(status: DocumentStatus) {
    this.pSelected = status;

    this.$emit('change', status);
  }
}
