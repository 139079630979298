












































































































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import {
  Document,
  DocumentStatus,
  File,
} from '@/types';

import { fileUtils } from '@/utils';

@Component
export default class ThirdPartyHistoryDocumentListItem extends BaseComponent {
  @Prop({ default: false }) loading?: boolean;

  @Prop({ default: () => ({}) }) document?: Document;

  @Prop({ default: () => ({}) }) options: any;

  get pLoading() {
    return this.loading;
  }

  get pDocument() {
    return this.document;
  }

  get attachments() {
    return this.pDocument?.attachments || [];
  }

  get highlighted() {
    const { documentId } = this.$route.query;

    return this.document?.id === documentId;
  }

  get sentDate() {
    const document = this.pDocument as any;

    return document?.sentAt || document?.signedAt || document?.assigneeStatusChangedAt;
  }

  handleCheckChange(selected: boolean) {
    const { id } = this.document as Document;

    this.$store.commit('thirdParty/setHistorySelected', { id, selected });

    this.$emit('checkChanged', this.document);
  }

  onStatusChange(status: DocumentStatus) {
    this.$store.commit('thirdParty/mutateHistoryObject', {
      ...this.document,
      status,
      selected: true,
    });
  }

  handleAddInfo() {
    this.$emit('showInfo', this.document);
  }

  get userFiles() {
    return this.isBusiness
      ? this.document?.files
      : (this.document?.files as any[] || []).filter((file: any) => file.uploadedBy === 'user');
  }

  get userComments() {
    return (this.document?.comments as any[] || []).filter((comment: any) => comment.accountType === 'user');
  }

  getFileName = (file: File) => fileUtils.parseFileName(file)

  getFilePath = (file: File) => fileUtils.parseFilePath(file)

  handleDownloadClick = (e: Event) => {
    const element = e.currentTarget as Element;
    const path = element.getAttribute('path') as string;

    const { token } = this.$route.params;

    (element as any).href = fileUtils.buildThirdPartyDownloadUrl(token, path);
  }

  handleUploadClick(document: Document) {
    this.$emit('onUpload', document);
  }
}
