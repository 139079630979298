import { User, UserLocalState } from '@/types';
import StorageBase from './storage';

export default class extends StorageBase {
  constructor() {
    super(localStorage);
  }

  set user(data: User) {
    this.setObject('a', data);
  }

  get user(): User {
    return this.getObject('a');
  }

  set token(data: string | null | undefined) {
    if (data) {
      this.set('b', data);
    }
  }

  get token(): string | null | undefined {
    return this.get('b');
  }

  set userState(data: UserLocalState) {
    this.setObject('c', data);
  }

  get userState(): UserLocalState {
    return this.getObject('c');
  }

  set sessionId(sessionId: string) {
    if (sessionId) {
      this.set('d', sessionId);
    }
  }

  get sessionId(): string {
    return this.get('d') || '';
  }

  set lastActiveAt(time: number) {
    this.set('e', time.toString());
  }

  get lastActiveAt(): number {
    const time = this.get('e') as string | '0';
    return parseInt(time, 10);
  }

  set recentSearches(queries: string[]) {
    this.setObject('f', queries);
  }

  get recentSearches(): string[] {
    return this.getObject('f') || [];
  }

  removeSessions(): void {
    this.remove('a');
    this.remove('b');
    this.remove('d');
    this.remove('e');
  }
}
