import { AxiosResponse } from 'axios';

import {
  IdentityResponse,
  IdentityStatusResponse,
  IdentityVerification,
  UpdateIdentityStatusRequest,
} from '@/types';

import { jwtApi } from './api_base';

export default {
  generateToken(): Promise<AxiosResponse<IdentityResponse>> {
    return jwtApi.post<IdentityResponse>('/identity-verification/token');
  },
  updateStatus(applicantId: string, payload: UpdateIdentityStatusRequest):
    Promise<AxiosResponse<IdentityStatusResponse>> {
    return jwtApi.put<IdentityStatusResponse>(`/identity-verification/status/${applicantId}`, payload);
  },
  checkStatus(): Promise<AxiosResponse<IdentityVerification>> {
    return jwtApi.get<IdentityVerification>('/identity-verification/status');
  },
};
