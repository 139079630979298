var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-workflows__lists__item",class:{
    'c-workflows__lists__item--success': _vm.pWorkflow.completed > 0
            && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
    'c-workflows__lists__item--primary': _vm.showDueDateWarning(_vm.pWorkflow)
      && _vm.pWorkflow.total > 0,
    }},[_c('div',{staticClass:"c-workflows__lists__item__title"},[_c('a',{staticClass:"is-flex is-flex-grow-1",on:{"click":_vm.handleWorkflowClick}},[_c('h4',[_vm._v(_vm._s(_vm.pWorkflow.name))])]),_c('div',{staticClass:"right"},[(_vm.pWorkflow.dueTaskCount)?_c('b-tag',{attrs:{"type":"flag-count is-primary is-inverted"}},[_c('img',{attrs:{"src":require("../../assets/flag2.svg"),"width":"18","alt":""}}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(' ')+_vm._s(_vm.pWorkflow.dueTaskCount)+" ")])]):_vm._e(),(_vm.pDetails && _vm.pDetails.assigneeStatusCount)?_c('b-tag',{staticClass:"envelope-icon",attrs:{"title":"one or more contacts has changed document(s)"}},[_c('b-icon',{attrs:{"icon":"email"}})],1):_vm._e(),(_vm.pHasActions)?_vm._t("actions",[_c('WorkflowActions',{attrs:{"workflow":_vm.pWorkflow},on:{"onActionClick":_vm.onActionClick}})]):_vm._e()],2)]),_c('a',{on:{"click":_vm.handleWorkflowClick}},[_c('div',{staticClass:"c-workflows__lists__item__content"},[(!_vm.isThirdParty)?_c('b-progress',{attrs:{"type":{
            'is-success': _vm.pWorkflow.completed > 0
              && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
            'is-primary': _vm.showDueDateWarning(_vm.pWorkflow) && _vm.pWorkflow.total > 0,
            'is-default': _vm.pWorkflow.completed === 0,
          },"size":"is-medium","max":100,"value":_vm.calculatePercentage(_vm.pWorkflow),"show-value":false}}):_vm._e(),_c('div',{staticClass:"progress-data"},[_c('div',{staticClass:"progress-data__left"},[(!_vm.isThirdParty)?_c('p',{staticClass:"sub-text",class:{
              'has-text-success': _vm.pWorkflow.completed > 0
                && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
              'has-text-primary': _vm.showDueDateWarning(_vm.pWorkflow) && _vm.pWorkflow.total > 0,
              'sub-text': _vm.pWorkflow.completed === 0,
            }},[_vm._v(" "+_vm._s(_vm.calculatePercentage(_vm.pWorkflow))+"% completed "),(_vm.pWorkflow.dueDate && _vm.pWorkflow.status !== 'completed')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRelative")(_vm.pWorkflow.dueDate,{ startWithCaps: true }))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"progress-data__right"},[_vm._l((_vm.detailContacts),function(contact,index){return _c('section',{key:contact.id,attrs:{"title":_vm._f("full-name")(contact)}},[(index < 3)?_c('Avatar',{attrs:{"name":_vm._f("full-name")(contact)}}):_vm._e()],1)}),(_vm.detailContacts.length > 3)?_c('span',{staticClass:"has-text-primary is-size-7 pl-1 has-text-weight-semibold"},[_vm._v(" + "+_vm._s(_vm.detailContacts.length - 3)+" ")]):_vm._e(),(!_vm.isBusiness)?_c('section',{staticClass:"ml-4"},[_c('Avatar',{attrs:{"name":_vm._f("full-name")(_vm.owner)}})],1):_vm._e()],2)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }