















































































import SignInBase from './SignInBase';

export default class SidebarSignIn extends SignInBase {}
