
















import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class DocumentTopTabBar extends BaseComponent {

}
