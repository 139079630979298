









import { Person } from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class ThirdPartyRequestedByBar extends BaseComponent {
  @Prop({ default: false }) loading?: boolean;

  @Prop({ default: 'REQUESTED BY' }) label?: string;

  @Prop() person?: Person;

  get pLoading() {
    return this.loading;
  }

  get pLabel() {
    return this.label;
  }

  get pPerson() {
    return this.person;
  }
}
