












import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class FileUpload extends BaseComponent {
  @Prop({ default: true }) showFile?: boolean

  @Prop({ default: null }) file?: File | null;

  @Watch('file')
  onFileChange() {
    if (this.file) {
      const maxFileSize = parseInt(process.env.VUE_APP_FILE_MAX_SIZE_MB || '20', 10);
      const fileSize = this.file.size / (1024 * 1024);
      if (fileSize > maxFileSize) {
        this.notify({
          message: this.$t('form.fileManager.fileUpload.maxSize') as string,
          type: 'is-danger',
        });
        this.file = null;
        return;
      }
      this.$emit('fileUpload', this.file);
    }
  }
}
