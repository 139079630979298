

























import { actions } from '@/store';
import { Company } from '@/types';
import { Component, Emit, Prop } from 'vue-property-decorator';

import BaseComponent from './base-component';

@Component
export default class CompanyList extends BaseComponent {
  @Prop({
    default: () => ({
      id: '',
      name: 'All Companies',
    }),
  })
  selected?: Company;

  get pSelected() {
    return this.selected;
  }

  get computed() {
    return {
      loading: this.$store.state.companyList.loading,
    };
  }

  @Emit('change')
  change(company: Company) {
    if (company.id !== this.selected?.id) {
      this.selected = company;
      // this.$emit('change', company);
    }
  }

  mounted() {
    this.$store.dispatch(actions.COMPANY_GET_ALL);
  }
}
