import { Component } from 'vue-property-decorator';

import { actions } from '@/store';
import { WorkflowDetails } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class Workflows extends BaseComponent {
  details: WorkflowDetails[] = [];

  getDetails(id: string) {
    return this.details.find((detail) => detail.workflow === id);
  }

  async loadDetails(workflows: string[]) {
    this.details = await this.$store
      .dispatch(actions.WORKFLOW_MULTIPLE_DETAILS, workflows);
  }
}
