






















import { Component } from 'vue-property-decorator';
import Layout from '@/layouts/Layout';

@Component
export default class CreateLayout extends Layout {}
