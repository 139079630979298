





















































import { SortItem } from '@/types';
import { Component, Prop } from 'vue-property-decorator';

import Workflow from './Workflow';

const createdAt: SortItem = {
  key: 'contact.createdAt',
  label: 'Added',
  icon: 'clock-outline',
};

const invitedAt: SortItem = {
  key: 'invitedAt',
  label: 'Invited',
  icon: 'clock-outline',
};

const acceptedAt: SortItem = {
  key: 'acceptedAt',
  label: 'Connected',
  icon: 'clock-outline',
};

const nameField: SortItem = {
  key: 'contact.firstName',
  label: 'Name',
  icon: 'sort-alphabetical-ascending',
};

@Component
export default class ViewWorkflowContacts extends Workflow {
  @Prop({ default: 0 }) activeTab?: number;

  get pActiveTab(): number | undefined {
    return this.activeTab;
  }

  set pActiveTab(activeTab: number | undefined) {
    this.activeTab = activeTab;
  }

  // eslint-disable-next-line class-methods-use-this
  get connectedSortItems() {
    return [acceptedAt, invitedAt, createdAt, nameField];
  }

  // eslint-disable-next-line class-methods-use-this
  get pendingSortItems() {
    return [invitedAt, createdAt, nameField];
  }

  onChange = (value: any) => {
    // ignore
  }
}
