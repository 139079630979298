


















import { Workflow } from '@/types';
import {
  Component,
  Emit,
  Prop,
  Watch,
} from 'vue-property-decorator';

import WorkflowsComponent from './workflows';

@Component
export default class WorkflowList extends WorkflowsComponent {
  @Prop({ default: true }) hasActions?: boolean;

  @Prop({ default: () => [] }) workflows?: Workflow[];

  @Prop() contact?: string;

  @Prop({ default: false }) isThirdParty?: boolean;

  get pHasActions() {
    return this.hasActions;
  }

  private get pWorkflows(): Workflow[] | undefined {
    return this.workflows;
  }

  private set pWorkflows(value: Workflow[] | undefined) {
    this.workflows = value;
  }

  @Watch('workflows')
  onWorkflowsChanged(newValue: Workflow[], oldValue: Workflow[]) {
    if (newValue?.length) {
      this.loadDetails(newValue.map((workflow) => workflow.id as string));
    }
  }

  get pIsThirdParty() {
    return this.isThirdParty;
  }

  @Emit('onActionClick')
  // eslint-disable-next-line class-methods-use-this
  onActionClick(workflow: Workflow, action: string) {
    // ignore
  }

  onToggle(contact: Workflow, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }
}
