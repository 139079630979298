


























































import { Component } from 'vue-property-decorator';
import WorkflowActions from './workflow-actions';

@Component
export default class WorkflowContactsQuickActions extends WorkflowActions {
}
