import HelloSign from 'hellosign-embedded';

import { stringToBoolean } from '@/utils/functions';

const client = new HelloSign({
  clientId: process.env.VUE_APP_HELLOSIGN_CLIENT_ID,
  testMode: stringToBoolean(process.env.VUE_APP_HELLOSIGN_TEST_MODE as string),
});

export default {
  client,
};
