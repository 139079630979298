import isFunction from 'lodash/isFunction';
import { AxiosRequestConfig } from 'axios';

export default {
  addHeader: (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.data instanceof FormData) {
      const formData = config.data as any;

      if (isFunction(formData.getHeaders)) {
        Object.assign(config.headers, formData.getHeaders());
      }
    }

    return config;
  },
};
