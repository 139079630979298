const actions = {
  AUTHENTICATE: 'auth/authenticate',
  REGISTER_USER: 'auth/register',
  VERIFY_EMAIL: 'auth/verifyEmail',
  SESSION_START: 'session/start',
  SESSION_END: 'session/end',
  USER_ME: 'users/me',
  COUNTRY_GET_ALL: 'country/getAll',
  COMPANY_GET_ALL: 'companyList/getAll',
  CONTACT_GET_ALL: 'contact/getAll',
  LOGIN_SHOW: 'ui/showLogin',
  LOGIN_HIDE: 'ui/hideLogin',
  EXPAND_SIDEBAR: 'ui/expandSidebar',
  COLLAPSE_SIDEBAR: 'ui/collapseSidebar',
  TOGGLE_SIDEBAR: 'ui/toggleSidebar',
  SIDEBAR_SHOW_ON_MOBILE: 'ui/showSidebarOnMobile',
  UI_SET_SIDEBAR: 'ui/setSidebar',
  SIGN_OUT: 'auth/signOut',
  TWO_FA_SEND_CODE: 'auth/send2FACode',
  TWO_FA_VERIFY_CODE: 'auth/verify2FACode',
  FORGOT_PASSWORD_REQUEST: 'password/forgot',
  RESET_PASSWORD_REQUEST: 'password/reset',
  IDENTITY_VERIFICATION_GENERATE_TOKEN: 'identityVerification/token',
  IDENTITY_VERIFICATION_UPDATE_STATUS: 'identityVerification/updateStatus',
  IDENTITY_VERIFICATION_CHECK_STATUS: 'identityVerification/checkStatus',
  SYNC_AUTH: 'auth/sync',
  SEARCH_CONTACTS: 'searchContacts/search',
  CONTACT_GET_BY_ID: 'contact/getById',
  CONTACT_MULTIPLE: 'contact/multiple',
  CONTACT_CREATE: 'contact/create',
  CONTACT_UPDATE: 'contact/update',
  CONTACT_DELETE: 'contact/delete',
  CONTACT_WORKFLOWS: 'searchContacts/workflows',
  WORKFLOWS_SEARCH: 'workflowSearch/search',
  WORKFLOWS_REQUESTS: 'workflowSearch/requests',
  WORKFLOW_GET_BY_ID: 'workflow/getById',
  WORKFLOW_CREATE: 'workflow/create',
  WORKFLOW_UPDATE: 'workflow/update',
  WORKFLOW_DELETE: 'workflow/delete',
  WORKFLOW_MULTIPLE_DETAILS: 'workflow/multipleDetails',
  WORKFLOW_DELETE_FILE: 'workflow/deleteFile',
  WORKFLOW_SEARCH_CONTACTS: 'workflowContacts/search',
  WORKFLOW_INVITE: 'workflowInvite/invite',
  WORKFLOW_INVITE_WITH_DOCUMENTS: 'workflowInvite/inviteWithDocuments',
  WORKFLOW_SUMMARY: 'workflowSummary/summary',
  TASK_SUMMARY: 'taskSummary/summary',
  TASK_LIST: 'tasks/getAll',
  ASSIGNED_TASK_LIST: 'tasks/getAssignedTasks',
  TASK_GET_BY_ID: 'tasks/getById',
  TASK_CREATE: 'tasks/create',
  TASK_GET_BY_GROUP_ID: 'tasks/getByGroupId',
  TASK_GROUP_UPDATE: 'tasks/groupUpdate',
  WORKFLOW_DOCUMENTS_LIST: 'workflowDocuments/list',
  WORKFLOW_DOCUMENTS_DETAIL: 'workflowDocuments/detail',
  WORKFLOW_DOCUMENTS_POST_COMMENT: 'workflowDocuments/comment',
  WORKFLOW_DOCUMENTS_POST_STATUS: 'workflowDocuments/changeStatus',
  WORKFLOW_DOCUMENTS_ASSIGN_FILES: 'workflowDocuments/assignFiles',
  WORKFLOW_DOCUMENTS_UPDATE: 'workflowDocuments/updateDocument',
  WORKFLOW_DOCUMENTS_SEND_DOCUMENTS: 'workflowDocuments/sendDocuments',
  WORKFLOW_DOCUMENTS_ESIGN: 'workflowDocuments/eSign',
  TEMPLATE_GET_ALL: 'templates/getAll',
  THIRD_PARTY_ACCEPT_INVITATION: 'thirdParty/accept',
  THIRD_PARTY_POST_DOCUMENT_COMMENT: 'thirdParty/postDocumentComment',
  THIRD_PARTY_DOCUMENT_COMMENTS: 'thirdParty/documentComments',
  THIRD_PARTY_FETCH_TASK: 'thirdParty/task',
  THIRD_PARTY_CHANGE_TASK_STATUS: 'thirdParty/changeTaskStatus',
  THIRD_PARTY_UPDATE_TASK: 'thirdParty/updateTask',
  THIRD_PARTY_TASK_COMMENTS: 'thirdParty/taskComments',
  THIRD_PARTY_POST_TASK_COMMENT: 'thirdParty/postTaskComment',
  FILE_MANAGER_GET_LIST: 'fileManager/getList',
  FILE_MANAGER_GET_RECENT: 'fileManager/getRecent',
  FILE_MANAGER_ADD_FOLDER: 'fileManager/addFolder',
  FILE_MANAGER_FILE_UPLOAD: 'fileManager/uploadFile',
  FILE_MANAGER_GET_DETAILS: 'fileManager/getDetails',
  FILE_MANAGER_ADD_TAGS: 'fileManager/addTags',
  FILE_MANAGER_GET_TAGS: 'fileManager/getTags',
  FILE_MANAGER_RENAME: 'fileManager/rename',
  FILE_MANAGER_DELETE: 'fileManager/delete',
  DOCUMENT_STATUS_LIST: 'documentStatuses/list',
  ACTIONS_LIST: 'actions/list',
  ESIGN_SIGN: 'eSign/sign',
  TAGS_GET_ALL: 'tags/getAll',
  PAYMENT_GATEWAY_PRICES: 'paymentGateway/prices',
  PAYMENT_GATEWAY_CREATE_SESSION: 'paymentGateway/createSession',
  PAYMENT_GATEWAY_CREATE_BILLING_SESSION: 'paymentGateway/createBillingSession',
  PAYMENT_SUBSCRIPTION: 'paymentGateway/subscription',
};

export default actions;
