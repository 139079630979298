





























































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { File } from '@/types';
import { fileUtils } from '@/utils';

@Component
export default class TaskInfo extends BaseComponent {
  @Prop({ required: true }) task?: any;

  comments = [];

  get showMarkAsDone() {
    return this.task.user.id === this.$store.state.auth.identity.id
      && this.task.status === 'pending';
  }

  get isAssignedToMe() {
    return this.task && this.task.action.code === 'assigned_to_me';
  }

  markAsDone() {
    this.$emit('action', 'mark-as-done');
  }

  sendReminder() {
    this.$emit('action', 'send-reminder');
  }

  getFilePath = (file: File) => fileUtils.parseFilePath(file)

  handleDownloadClick = (e: Event) => {
    const element = e.currentTarget as Element;
    const path = element.getAttribute('path') as string;

    (element as any).href = fileUtils.buildDownloadUrl(path);
  }

  mounted() {
    if (this.task.action.code === 'comment_document') {
      this.$store.dispatch('tasks/getComments', this.$route.params.id).then((comments) => {
        this.comments = comments;
      });
    }
  }
}
