import { AxiosRequestConfig } from 'axios';

import storage from '@/storage';

const getHeaders = () => {
  const { token } = storage;

  if (token) {
    return {
      Authorization: token ? `Bearer ${token}` : '',
    };
  }

  return {};
};

export default (config: AxiosRequestConfig): AxiosRequestConfig => {
  Object.assign(config.headers, getHeaders());

  return config;
};
