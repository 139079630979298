








import { Component, Prop } from 'vue-property-decorator';

import { BreadcrumbItem, Task, Workflow } from '@/types';

import TaskComponent from './Task';

@Component
export default class ViewAHTask extends TaskComponent {
  @Prop() data?: Task;

  get pBreadcrumbs() {
    const workflow = (this.data?.workflow as Workflow);

    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      // {
      //   title: 'dashboard.menu.workflows',
      //   path: '/workflows/requests',
      // },
      // {
      //   title: workflow?.name || '...',
      //   path: `/workflows/${workflow?.id}`,
      // },
      {
        title: 'dashboard.menu.tasks',
        path: `/workflows/${workflow?.id}`,
      },
      {
        title: this.data?.title,
      },
    ] as BreadcrumbItem[];
  }

  get name() {
    return this.data?.title;
  }

  onDataLoaded(data: Task) {
    this.data = data;
  }
}
