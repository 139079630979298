




















































import cloneDeep from 'lodash/cloneDeep';
import { Component, Prop } from 'vue-property-decorator';

import {
  BreadcrumbItem,
  Task,
  defaultQuery,
  PaginatedResult,
  emptyPaginatedResult,
  SortItem,
} from '@/types';
import { actions } from '@/store';

import TaskComponent from './Task';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:DESC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'dueDate:DESC',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'title:ASC',
    label: 'Title asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'title:DESC',
    label: 'Title desc',
    icon: 'sort-alphabetical-descending',
  },
];

const queryWithFilters = cloneDeep(defaultQuery);

queryWithFilters.filters = {
  ...queryWithFilters.filters,
  assignedTo: 'contact',
};

@Component
export default class AssignedTasks extends TaskComponent {
  private query = queryWithFilters;

  sortItems = sortItems;

  selectedSort = sortItems[0];

  dataFetched = false;

  @Prop({ default: () => Date.now() }) taskListKey?: number;

  @Prop({ default: false }) selectable?: boolean;

  @Prop({ default: emptyPaginatedResult })
  result?: PaginatedResult<string>;

  @Prop({ default: () => [] }) selected?: Task[];

  @Prop({ default: false }) allChecked?: boolean;

  // eslint-disable-next-line class-methods-use-this
  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.tasks',
      },
    ] as BreadcrumbItem[];
  }

  get pSelectable() {
    return this.selectable;
  }

  get pTaskListKey() {
    return this.taskListKey;
  }

  get pData() {
    const { groupedTasks } = this.$store.state.tasks;
    const results = groupedTasks.data.results
      .map((id: string) => this.$store.state.task.groupedData[id]);

    return {
      ...groupedTasks.data,
      results,
    };
  }

  getTaskById(id: string) {
    return this.$store.getters['task/getById'](id);
  }

  get pSelected() {
    return this.selected;
  }

  get pAllChecked(): boolean | undefined {
    return this.allChecked;
  }

  set pAllChecked(value: boolean | undefined) {
    this.allChecked = value;
  }

  // onSortChange(item: SortItem) {
  //   let { filters } = this.query;

  //   this.selectedSort = item;

  //   filters = {
  //     ...filters,
  //     _sort: item.key,
  //   };

  //   this.loadData({
  //     page: 1,
  //     filters,
  //   });
  // }

  // onContactChange(contact: Contact) {
  //   let filters = {};

  //   if (contact.id) {
  //     filters = {
  //       contact: contact.id,
  //     };
  //   }

  //   this.loadData({
  //     page: 1,
  //     filters,
  //   });
  // }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  mounted() {
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
      };

      this.selected = [];

      this.result = await this.$store.dispatch(
        actions.ASSIGNED_TASK_LIST,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  onActionClick(task: Task, action: string) {
    switch (action) {
      case 'invite-to-workflow':
        this.$router.push({ name: 'WorkflowInvite', query: { tasks: task.id as string } });
        break;
      case 'edit':
        this.$router.push({ name: 'EditContact', params: { id: task.id as string } });
        break;
      case 'delete':
        // this.handleDelete(task);
        break;
      default:
        // do nothing
    }
  }

  onToggle(task: Task, checked: boolean) {
    this.selected = checked
      ? [...this.selected || [], task]
      : this.selected?.filter((c) => c.id !== task.id);
  }

  async onDeleteClick(tasks: Task[]) {
    try {
      const promises: Promise<any>[] = [];

      tasks.forEach((task) => {
        promises.push(this.$store.dispatch(actions.CONTACT_DELETE, task.id));
      });

      await Promise.all(promises);

      this.notify({
        message: this.$t('screen.contacts.delete.success.message') as string,
      });

      this.loadData({ page: 1 });
    } catch (error) {
      this.handleError(error, 'screen.contacts.delete.error.title');
    }
  }
}
