



































































































































import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { File } from '@/types';
import { fileUtils } from '@/utils';

@Component
export default class GlobalFileActionsModal extends BaseComponent {
  folderName = '';

  file = null;

  modal = {
    active: false,
    tab: 0,
    error: '',
  }

  onlyUploadMode = false;

  showCreateFolder = false;

  folderPath = '';

  breadcrumbs: File[] = [];

  selectable: string[] = [];

  fileSelected: File | null = null;

  changeTab(tab: number) {
    this.modal.tab = tab;
  }

  @Watch('modal.active')
  emitModalClose() {
    if (!this.modal.active) {
      this.$emit('modalClose');
    }
  }

  @Watch('modal.tab')
  onTabChange() {
    if (this.modal.tab === 0) {
      this.modal = {
        active: true,
        tab: 0,
        error: '',
      };
      this.selectable = ['file'];
    } else {
      this.modal = {
        active: true,
        tab: 1,
        error: '',
      };
      this.selectable = [];
    }
  }

  @Watch('folderPath')
  onChangeFolderPath() {
    this.showCreateFolder = false;
  }

  get isInsideWorkflowFolder() {
    if (this.breadcrumbs.length) {
      return fileUtils.isWorkflowFolder(this.breadcrumbs[0]);
    }
    return false;
  }

  hideCreateFolder() {
    this.folderName = '';
    this.showCreateFolder = false;
  }

  onClickAssign() {
    this.fileSelected = null;
    this.breadcrumbs = [];
    this.folderPath = '';
    this.modal.tab = 0;
    this.folderName = '';
    this.file = null;
    this.onTabChange();
  }

  onClickUpload() {
    this.showCreateFolder = false;
    this.fileSelected = null;
    this.breadcrumbs = [];
    this.folderPath = '';
    this.modal.tab = 1;
    this.folderName = '';
    this.file = null;
    this.onTabChange();
  }

  onClickCreateMode() {
    this.onClickUploadOnlyMode();
    this.showCreateFolder = true;
  }

  onClickUploadOnlyMode() {
    this.onlyUploadMode = true;
    this.onClickUpload();
  }

  handleFileUpload(file: any) {
    this.file = file;
  }

  async doFileUpload() {
    if (!this.file) {
      this.notify({
        message: 'Please select a file',
        type: 'is-danger',
      });
      return;
    }
    try {
      if (this.folderPath === '') {
        this.notify({
          message: 'File cannot be uploaded to root directory.',
          type: 'is-danger',
        });
        return;
      }
      await this.$store.dispatch(actions.FILE_MANAGER_FILE_UPLOAD, {
        path: this.folderPath,
        files: this.file,
      });
      this.file = null;
      this.notify({
        message: 'File uploaded successfully.',
        type: 'is-success',
      });
    } catch (err) {
      this.handleError(err, 'error.generic');
    }
  }

  doAddFolder() {
    this.modal.error = '';
    if (!this.folderName.trim().length) {
      this.modal.error = 'Name can\'t be empty';
      return;
    }
    if (this.folderName.trim().length > 120) {
      this.modal.error = 'Name cannot exceed 120 characters';
      return;
    }
    this.$store.dispatch(actions.FILE_MANAGER_ADD_FOLDER,
      `${this.folderPath}/${this.folderName.trim()}`).then(() => {
      this.notify({
        message: 'Folder created successfully.',
        type: 'is-success',
      });
      this.folderName = '';
      this.showCreateFolder = false;
    }).catch((err) => {
      this.handleError(err, 'error.generic');
    });
  }

  getFileName = (file: File) => fileUtils.parseFileName(file)

  changePath(file: File) {
    this.folderPath = file.pathLower;
    this.onPathChanged(file);
  }

  onPathChanged(file: File) {
    if (!this.breadcrumbs.find((f) => f.pathLower === file.pathLower)) {
      this.breadcrumbs.push(file);
    }
  }

  handleRootBreadCrumbClick() {
    this.breadcrumbs = [];
    this.folderPath = '';
  }

  handleBreadCrumbClick(index: number) {
    if (index < this.breadcrumbs.length - 1) {
      this.breadcrumbs = this.breadcrumbs.splice(0, index + 1);
      this.folderPath = this.breadcrumbs[this.breadcrumbs.length - 1].pathLower;
    }
  }

  onFileSelect(file: File) {
    if (file.type === 'file') {
      this.fileSelected = file;
    }
  }

  handleFileSelected() {
    this.$emit('fileSelected', this.fileSelected);
    this.modal.active = false;
  }
}
