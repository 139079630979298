import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  Query,
  PaginatedResult,
} from '@/types';
import { Task as api } from '@/api';
import { RootState, TasksState } from '../states';

const getDefaultState = (): TasksState => ({
  groupedTasks: {
    data: {
      results: [],
      pagination: {
        page: 1,
        pageSize: 10,
      },
    },
    loading: false,
    error: null,
  },
  data: {
    results: [],
    pagination: {
      page: 0,
      pageSize: 5,
    },
  },
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<TasksState, RootState> = {
  async summary({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query);
        const { data } = resp;
        const result = {
          ...data,
          results: data.results.map((task) => task.id),
        };

        commit('setLoading', false);
        commit('setTasks', result);
        commit('task/setTasks', data.results, { root: true });

        resolve(data.results);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: TasksState, loading: boolean) {
    state.loading = loading;
  },
  removeTasks(state: TasksState, id: string) {
    state.data = {
      ...state.data,
      results: state.data.results.filter((key) => key !== id),
    };
  },
  setTasks(state: TasksState, data: PaginatedResult<string>) {
    state.data = data;
  },
  setError(state: TasksState, error: CustomError) {
    state.error = error;
  },
  reset(state: TasksState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): TasksState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const taskSummary: Module<TasksState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
