





































import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import actions from '@/store/constants';

@Component
export default class RegistrationSuccess extends BaseComponent {
  showLogin() {
    this.$store.dispatch(actions.LOGIN_SHOW);
  }
}
