







































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class DashboardQuickActions extends BaseComponent {
  createFolder() {
    this.$emit('action', 'create-folder');
  }

  fileUpload() {
    this.$emit('action', 'file-upload');
  }
}
