
























































































































































































import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import storage from '@/storage';
import { actions } from '@/store';
import {
  Comment,
  File,
  UpdateTaskPayload,
  TaskStatusPayload,
  TaskCommentPayload,
  ThirdPartyResponse,
} from '@/types';
import { fileUtils } from '@/utils';

@Component
export default class ThirdPartyTask extends BaseComponent {
  attachments = [];

  comment = '';

  modalOptions = {
    show: false,
    attachments: [],
  };

  created() {
    this.loadData();
  }

  get task() {
    return this.$store.state.thirdParty.task?.data || {};
  }

  get loading() {
    return this.$store.state.thirdParty.task?.loading || false;
  }

  get saving() {
    return this.$store.state.thirdParty.task?.saving || false;
  }

  get action() {
    return this.$store.state.thirdParty.task?.data?.action || {};
  }

  get user() {
    return this.$store.state.thirdParty.task?.data?.user;
  }

  get contact() {
    return this.$store.state.thirdParty.task?.data?.contact;
  }

  get workflowName() {
    return this.$store.state.thirdParty.task?.data?.workflow?.name;
  }

  get files() {
    return this.$store.state.thirdParty.task?.data?.files || [];
  }

  get comments() {
    const comments = this.$store.state.thirdParty.task?.data?.comments || [];

    return [...comments].sort(this.sortComments);
  }

  sortComments = (a: Comment, b: Comment) => {
    if (a.postedAt > b.postedAt) {
      return -1;
    }

    if (a.postedAt < b.postedAt) {
      return 1;
    }

    return 0;
  }

  getFileName = (file: File) => fileUtils.parseFileName(file)

  getFilePath = (file: File) => fileUtils.parseFilePath(file)

  deleteAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  handleDownloadClick = (e: Event) => {
    const element = e.currentTarget as Element;
    const path = element.getAttribute('path') as string;
    const { token } = this.$route.params;

    (element as any).href = fileUtils.buildThirdPartyDownloadUrl(token, path);
  }

  async loadData() {
    const { token, id } = this.$route.params;

    if (token && id) {
      await this.$store.dispatch(actions.THIRD_PARTY_FETCH_TASK, { token, id });

      this.$emit('onDataLoaded', this.$store.state.thirdParty.task?.data);

      const { user } = storage;

      if (user) {
        const resp = await this.$store.dispatch('thirdParty/detail', token) as ThirdPartyResponse;

        if (resp.contact.email === user.email) {
          this.$router.replace({ name: 'ViewAHTask' });
        }
      }
    }
  }

  handleShowModal() {
    this.modalOptions = {
      show: true,
      attachments: [],
    };
  }

  handleCloseModal() {
    this.modalOptions.show = false;
  }

  handleModalSave() {
    this.modalOptions.show = false;

    this.modalOptions.attachments.forEach((attachment: any) => {
      if (!this.attachments.some((f: any) => f.name === attachment.name)) {
        this.attachments.push(attachment as never);
      }
    });
  }

  async handleMarkAsDone() {
    const { token, id } = this.$route.params;

    try {
      if (token && id) {
        const payload: TaskStatusPayload = {
          id,
          token,
          status: 'completed',
        };

        await this.$store.dispatch(actions.THIRD_PARTY_CHANGE_TASK_STATUS, payload);
        this.loadData();
      }
    } catch (error) {
      this.handleError(error, 'form.thirdParty.task.status.change.error.title');
    }
  }

  async handleSave() {
    const { token, id } = this.$route.params;

    try {
      if (token && id) {
        const payload: UpdateTaskPayload = {
          id,
          token,
          files: this.attachments,
        };

        await this.$store.dispatch(actions.THIRD_PARTY_UPDATE_TASK, payload);
        this.attachments = [];
        this.loadData();
      }
    } catch (error) {
      this.handleError(error, 'form.thirdParty.task.status.change.error.title');
    }
  }

  async handlePostComment() {
    const { token, id } = this.$route.params;

    try {
      if (token && id) {
        const payload: TaskCommentPayload = {
          id,
          token,
          comment: this.comment,
        };

        await this.$store.dispatch(actions.THIRD_PARTY_POST_TASK_COMMENT, payload);
        this.comment = '';
      }
    } catch (error) {
      this.handleError(error, 'form.thirdParty.task.comment.post.error.title');
    }
  }
}
