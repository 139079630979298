








































































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class ThirdPartyUpload extends BaseComponent {
  actions = []

  error = {
    message: false,
    action: false,
    file: false,
  }

  async getTokenInformation() {
    const { token } = this.$route.params;
    const resp = await this
      .$store.dispatch('thirdParty/getDocInfo', token);
  }

  async documentActions() {
    const result = await this.$store.dispatch('thirdPartyActions/documentUploadAction');
    this.actions = result;
  }

  get requestActions() {
    return this.$store.getters['thirdPartyActions/getActions'];
  }

  get requestedBy() {
    return this.$store.getters['thirdParty/requestedBy'];
  }

  get contact() {
    return this.$store.getters['thirdParty/assignedTo'];
  }

  get assignedWorkflow() {
    return this.$store.getters['thirdParty/getWorkflow'];
  }

  get document() {
    return this.$store.getters['thirdParty/getCurrentPageDocument'];
  }

  created() {
    this.getTokenInformation();
    this.documentActions();
  }

  clickAddBtn() {
    let flag = true;
    if (this.document.upload.message === '') {
      flag = false;
      this.error.message = true;
    } else {
      this.error.message = false;
    }
    if (this.document.upload.action.name === '') {
      flag = false;
      this.error.action = true;
    } else {
      this.error.action = false;
    }

    // if (this.document.upload.fileDocuments.length === 0) {
    //   flag = false;
    //   this.error.file = true;
    // } else {
    //   this.error.file = false;
    // }

    if (flag) {
      this.document.upload.isAdded = true;
      const { token } = this.$route.params;
      this.$router.push(`/3p/${token}/request`);
    }
  }

  clearAll() {
    this.document.upload.fileDocuments = [];
    this.document.upload.message = '';
    this.document.upload.action.id = 'null';
    this.document.upload.action.name = '';
    this.document.upload.action.code = '';

    this.document.upload.isAdded = false;
    const { token } = this.$route.params;
    this.$router.push(`/3p/${token}/request`);
  }
}
