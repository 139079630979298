







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChannelList extends Vue {
  @Prop() private channels!: string[];
}
