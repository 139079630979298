















import { Component } from 'vue-property-decorator';
import { init } from 'onfido-sdk-ui';

import {
  IdentityResponse,
  IdentityStatusResponse,
  IdentityVerification,
} from '@/types';
import { actions } from '@/store';
import { identityVerificationStatus } from '@/utils/constants';
import BaseComponent from './base-component';

@Component
export default class Onfido extends BaseComponent {
  // private tokenResponse?: Promise<IdentityResponse>;

  private onfidoHandler?: any;

  /*
  created() {
    this.generateToken();
  }
  */

  mounted() {
    this.init();
  }

  beforeDestroy() {
    if (this.onfidoHandler) {
      this.onfidoHandler.tearDown();
    }
  }

  // generateToken() {
  //   this.tokenResponse = this.$store.dispatch(actions.IDENTITY_VERIFICATION_GENERATE_TOKEN);
  // }

  // eslint-disable-next-line class-methods-use-this
  async init() {
    if (this.onfidoHandler) {
      this.onfidoHandler.tearDown();
      this.onfidoHandler = undefined;
    }

    let verified: IdentityVerification;

    try {
      verified = await this
        .$store.dispatch(actions.IDENTITY_VERIFICATION_CHECK_STATUS) as IdentityVerification;
    } catch (error) {
      const message = this.parseMessage(error);

      this.$buefy.dialog.alert({
        title: this.$t('form.identityVerification.error.title') as string,
        message,
        confirmText: this.$t('retry') as string,
        closeOnConfirm: true,
        onConfirm: () => {
          this.init();
        },
        canCancel: true,
        onCancel: () => {
          this.$router.replace('/');
        },
      });

      return;
    }

    if (identityVerificationStatus.isVerified(verified.status)) {
      this.$buefy.dialog.alert({
        title: this.$t('form.identityVerification.heading') as string,
        message: this.$t('form.identityVerification.alreadyComputed') as string,
        type: 'is-success',
        onConfirm: () => {
          this.$router.replace('/dashboard');
        },
      });

      return;
    }

    let resp: IdentityResponse;

    try {
      resp = await this.$store.dispatch(actions.IDENTITY_VERIFICATION_GENERATE_TOKEN);
    } catch (error) {
      const message = this.parseMessage(error);

      this.$buefy.dialog.alert({
        title: this.$t('form.identityVerification.error.title') as string,
        message,
        confirmText: this.$t('retry') as string,
        closeOnConfirm: true,
        onConfirm: () => {
          this.init();
        },
      });

      return;
    }

    this.onfidoHandler = init({
      token: resp?.token,
      containerId: 'identity-mount',
      /*
      isModalOpen: true,
      useModal: true,
      onModalRequestClose: () => {
        this.$router.replace('/');
        this.showLogin();
      },
      */
      onComplete: async (data: any) => {
        if (data) {
          const payload = {
            applicantId: resp?.applicantId,
            payload: {
              status: identityVerificationStatus.verified,
            },
          };

          try {
            const result: IdentityStatusResponse = await this.$store
              .dispatch(actions.IDENTITY_VERIFICATION_UPDATE_STATUS, payload);

            this.notify({
              message: this.$t('form.identityVerification.success.message') as string,
              type: 'is-success',
            });

            if (result.user) {
              this.$router.replace('/dashboard');
            }
          } catch (error) {
            this.handleError(error, 'form.identityVerification.error.title');
          }
        }
      },
      steps: [
        /*
        {
          type: 'welcome',
          options: {
            title: '',
          },
        },
        */
        'document',
        'face',
        'complete',
      ],
    });
  }
}
