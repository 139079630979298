































import {
  Contact,
  defaultQuery,
  SortItem,
  PaginatedResult,
  emptyPaginatedResult,
} from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import { actions } from '@/store';
import Workflow from './Workflow';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:DESC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'dueDate:DESC',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'title:ASC',
    label: 'Title asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'title:DESC',
    label: 'Title desc',
    icon: 'sort-alphabetical-descending',
  },
];

@Component
export default class ViewWorkflowTaskList extends Workflow {
  private query = defaultQuery;

  dataFetched = false;

  result: PaginatedResult<string> = emptyPaginatedResult();

  sortItems = sortItems;

  selectedSort = sortItems[0];

  selectedContact?: Contact;

  @Prop({ default: () => ({}) }) filters?: object;

  get loading() {
    return this.$store.state.tasks.loading;
  }

  get pData() {
    return {
      ...this.result,
      results: this.result?.results.map((id: string) => ({ ...this.getTaskById(id) })),
    };
  }

  onContactChange(contact: Contact) {
    this.selectedContact = contact;

    this.loadData({
      page: 1,
    });
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;

    this.loadData({
      page: 1,
    });
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getTaskById(id: string) {
    return this.$store.getters['task/getById'](id);
  }

  created() {
    this.loadData();
  }

  async loadData(query: any = {}) {
    try {
      let filters: any = {
        _sort: this.selectedSort.key,
      };

      if (this.selectedContact?.id) {
        filters = {
          ...filters,
          contact: this.selectedContact.id as string,
        };
      }

      this.query = {
        ...this.query,
        ...query,
        filters: {
          ...this.filters,
          ...filters,
        },
      };

      this.result = await this.$store.dispatch(
        actions.TASK_LIST,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }
}
