










































































































import get from 'lodash/get';
import { actions } from '@/store';
import {
  Company,
  Contact,
  PaginatedResult,
  SortItem,
  WorkflowContact,
} from '@/types';
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class WorkflowContacts extends BaseComponent {
  getField = get;

  intervalHandler: any = 0;

  ticker = Date.now();

  dataFetched = false;

  @Prop({ default: () => [] }) sortItems?: SortItem[];

  @Prop() selectedSort?: SortItem;

  @Prop() company?: string;

  @Prop() workflow?: string;

  @Prop() status?: string;

  @Prop({ default: () => [] }) workflowContactIds?: string[];

  @Prop() invitingContactId?: string | null | undefined;

  @Prop({ default: 'screen.workflows.contact.empty.connectedContacts' }) emptyMessage?: string

  get pSortItems() {
    return this.sortItems;
  }

  get pWorkflow() {
    return this.workflow;
  }

  get pStatus() {
    return this.status;
  }

  get pWorkflowContactIds() {
    return this.workflowContactIds;
  }

  get pData() {
    // Note: do not remove this line as it makes the proprty to rerender
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const workflowContacts = this.$store.state.workflowContacts.ids;

    const ids = this.workflowContactIds || [];
    const data = (ids).map((id: string) => (this.getById(id)));

    let arr = data;

    if (this.company) {
      arr = data
        .filter((wc) => (((wc.contact as Contact).company as Company).id
        || (wc.contact as Contact).company as string) === this.company);
    }

    arr.sort(this.sort);

    return arr;
  }

  get pEmptyMessage() {
    return this.emptyMessage;
  }

  getById(id: string) {
    const workflowContact: WorkflowContact = this.$store.getters['workflowContacts/getById'](id);
    workflowContact.contact = this.$store.getters['contactList/getById']((workflowContact.contact as Contact).id || workflowContact.contact);

    return workflowContact;
  }

  created() {
    this.loadStaleData();
    this.loadData();

    this.createTicker();
  }

  createTicker() {
    this.ticker = Date.now();
    this.intervalHandler = setInterval(() => {
      this.ticker = Date.now();
    }, 10000);
  }

  async loadData(query = {}) {
    try {
      const result = await this.$store.dispatch(
        actions.WORKFLOW_SEARCH_CONTACTS,
        {
          id: this.workflow,
          status: this.status,
          query,
        },
      );

      this.workflowContactIds = result.results;
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  async invite(e: Event, workflowContact: WorkflowContact) {
    e.preventDefault();

    this.invitingContactId = workflowContact.id;

    try {
      await this.$store.dispatch(actions.WORKFLOW_INVITE, {
        workflow: workflowContact.workflow,
        contact: (workflowContact.contact as Contact).id,
      });

      this.loadData(); // TODO get rid of api call and update the ui
      // this.contactListKey = Date.now();
    } catch (error) {
      this.handleError(error, 'form.workflowInvite.error.title');
    }
    this.invitingContactId = null;
  }

  onActionClick(e: Event, wc: WorkflowContact, action: string) {
    e.preventDefault();

    const contact = (wc.contact as Contact).id ? (wc.contact as Contact).id : wc.contact as string;

    switch (action) {
      case 'create-task':
        this.$router.push({
          name: 'CreateTask',
          query: {
            contacts: contact as string,
            workflow: this.workflow,
          },
        });
        break;
      case 'edit':
        this.$router.push({ name: 'EditContact', params: { id: contact as string } });
        break;
      default:
        this.$emit('onActionClick', this.workflow, wc.contact, action);
    }
  }

  onCompanyChange(company: Company) {
    this.company = company.id;
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;
  }

  loadStaleData() {
    if (this.$store.state.workflowContacts.ids[this.workflow || ''] && this.$store.state.workflowContacts.ids[this.workflow || ''][this.status || '']) {
      const data = this.$store.state.workflowContacts.ids[this.workflow || ''][this.status || ''] as PaginatedResult<string>;
      this.workflowContactIds = data.results;
    }
  }

  sort(a: any, b: any) {
    // this.dateField = 'invitedAt';
    // this.selectedSort?.key || (this.status === 'accepted' ? 'acceptedAt' : 'invitedAt');
    const field = this.selectedSort?.key || 'invitedAt';

    const time1 = new Date(get(a, field)).getTime();
    const time2 = new Date(get(b, field)).getTime();

    return time2 - time1;
  }

  destroyed() {
    clearInterval(this.intervalHandler);
  }
}
