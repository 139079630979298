import ms from 'ms';
import {
  Task,
  Contact,
} from '@/types';
import {
  Component, Emit, Prop, Watch,
} from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class TaskListItem extends BaseComponent {
  @Prop() task?: Task;

  get pTask() {
    return this.task;
  }

  @Emit('onActionClick')
  // eslint-disable-next-line class-methods-use-this
  onActionClick(task: Task, action: string) {
    // ignore
  }

  getUser() {
    return this.$store.state.auth.identity;
  }

  // eslint-disable-next-line class-methods-use-this
  showDueDateWarning(task: Task) {
    const duration = ms(process.env.VUE_APP_TASK_OVERDUE_BY);
    return task.dueDate && Date.now() > (new Date(task.dueDate).getTime() - duration);
  }

  get contactNames() {
    const filters = this.$options.filters as any;
    const fullName = filters['full-name'];

    if (this.task?.contact) {
      return fullName(this.task?.contact);
    }

    const names = (this.task?.contacts as Contact[] || []).map((contact) => fullName(contact));

    return names.join(', ');
  }

  get isOwner() {
    return this.task?.assignedBy === 'user'
      // eslint-disable-next-line no-underscore-dangle
      && (this.task?.user as any)._id === this.$store.state.auth.identity.id;
  }

  protected isCompleted = this.pTask?.status === 'completed';

  onToggle(checked: boolean) {
    this.$emit('onToggle', this.task, checked);
  }
}
