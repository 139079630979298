








































































































import { Component } from 'vue-property-decorator';

import { RegistrationRequest } from '@/types';
import { actions } from '@/store';
import {
  required,
  max,
  isStrongPassword,
} from '../utils/validations';

import BaseComponent from './base-component';

const { REGISTER_USER } = actions;

@Component
export default class Login extends BaseComponent {
  validators = {
    firstName: [
      required(this.$t('required.field', [this.$t('firstName')])),
      max(30, this.$t('tooLong', [this.$t('firstName')])),
    ],
    lastName: [
      required(this.$t('required.field', [this.$t('lastName')])),
      max(30, this.$t('tooLong', [this.$t('lastName')])),
    ],
    email: [
      required(this.$t('required.field', [this.$t('workEmail')])),
      max(50, this.$t('tooLong', [this.$t('workEmail')])),
    ],
    mobile: [
      required(this.$t('required.field', [this.$t('mobileNumber')])),
      max(15, this.$t('tooLong', [this.$t('mobileNumber')])),
    ],
    country: required(this.$t('required.default')),
    password: [
      required(this.$t('required.field', [this.$t('createPassword')])),
      isStrongPassword('Password must match the password policy'),
    ],
    confirmPassword: [
      required(this.$t('required.field', [this.$t('confirmPassword')])),
      max(20, this.$t('tooLong', [this.$t('password')])),
      this.passwordMismatched,
    ],
    terms: required(this.$t('required.field', [this.$t('this')])),
  };

  private passwordMismatched(value: any, values: any) {
    return value === values.password ? undefined : this.$t('form.signUp.error.passwordMismatched');
  }

  submit(values: any): void {
    if (this.snackbarRef) {
      this.snackbarRef.close();
    }

    const payload: RegistrationRequest = {
      username: values.email.trim().toLowerCase(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email.trim().toLowerCase(),
      country: values.country,
      mobile: values.country.dialCode + values.mobile.trim(),
      password: values.password,
      tnc: values.tnc,
      optIn: values.optIn || false,
    };

    this.$store.dispatch(REGISTER_USER, payload)
      .then(() => {
        this.$router.replace('/registration-success');
      })
      .catch((err) => {
        this.handleError(err, 'form.signUp.error.title');
      });
  }

  showLogin() {
    this.$store.dispatch(actions.LOGIN_SHOW);
  }
}
