




















































import cloneDeep from 'lodash/cloneDeep';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { sortByDateDesc } from '@/utils/functions';

import {
  BreadcrumbItem,
  Workflow,
  defaultQuery,
  SortItem,
} from '@/types';
import { actions } from '@/store';

import WorkflowComponent from './Workflow';

const sortItems: SortItem[] = [
  {
    key: 'dueDate',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'name-asc',
    label: 'Name asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'name-desc',
    label: 'Name desc',
    icon: 'sort-alphabetical-descending',
  },
];

const queryWithFilters = cloneDeep(defaultQuery);

queryWithFilters.filters = {
  ...queryWithFilters.filters,
  status: 'completed',
};

@Component
export default class WorkflowsCompleted extends WorkflowComponent {
  private query = queryWithFilters;

  dataFetched = false;

  workflows: string[] = [];

  @Prop({ default: () => Date.now() }) workflowListKey?: number;

  @Prop({ default: () => [] }) selected?: Workflow[];

  @Prop({ default: false }) allChecked?: boolean;

  sortItems = sortItems;

  selectedSort = sortItems[0];

  // eslint-disable-next-line class-methods-use-this
  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.workflows',
      },
    ];
  }

  get pWorkflowListKey() {
    return this.workflowListKey;
  }

  get loading() {
    return this.$store.state.workflows.loading;
  }

  get pWorkflows() {
    const workflows = (this.workflows || []).map((id: string) => ({ ...this.getWorkflowById(id) }));
    return workflows.sort(this.sort);
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;
  }

  sort(a: Workflow, b: Workflow) {
    switch (this.selectedSort.key) {
      case 'name-asc':
      case 'name-desc':
        return this.sortByName(this.selectedSort.key)(a, b);
      default:
        return sortByDateDesc(this.selectedSort.key)(a, b);
    }
  }

  sortByName = (key: string) => (a: Workflow, b: Workflow) => {
    const name1 = (a.name).toLowerCase();
    const name2 = (b.name).toLowerCase();
    if (name1 > name2) {
      return key === 'name-desc' ? -1 : 1;
    }

    if (name1 < name2) {
      return key === 'name-desc' ? 1 : -1;
    }

    return 0;
  }

  sortByDate = (key: string) => (a: Workflow, b: Workflow) => {
    const time = Number.MAX_VALUE;
    let date1 = a.dueDate ? new Date(a.dueDate).getTime() : time;
    let date2 = b.dueDate ? new Date(b.dueDate).getTime() : time;

    if (!a.dueDate) {
      date1 = a.updatedAt ? new Date(a.updatedAt).getTime() : time;
      date2 = b.updatedAt ? new Date(b.updatedAt).getTime() : time;
    }

    return date1 - date2;
  }

  get pSelected() {
    return this.selected;
  }

  get pAllChecked(): boolean | undefined {
    return this.allChecked;
  }

  set pAllChecked(value: boolean | undefined) {
    this.allChecked = value;
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getWorkflowById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  mounted() {
    this.loadStaleData();
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
      };

      this.selected = [];

      this.workflows = await this.$store.dispatch(
        actions.WORKFLOWS_SEARCH,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  @Watch('$store.state.workflowSearch.data')
  loadStaleData() {
    this.workflows = this.$store.state.workflowSearch.data as string[];
  }

  onActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'delete':
        this.handleDelete(workflow);
        break;
      default:
        // do nothing
    }
  }

  onGlobalToggle(checked: boolean) {
    if (checked) {
      this.selected = this.pWorkflows;
    } else {
      this.pAllChecked = false;
      this.selected = [];
    }

    this.workflowListKey = Date.now();
  }

  onToggle(workflow: Workflow, checked: boolean) {
    this.selected = checked
      ? [...this.selected || [], workflow]
      : this.selected?.filter((c) => c.id !== workflow.id);
  }
}
