













import { Component, Prop, Vue } from 'vue-property-decorator';

import { skipIdentityVerification } from '@/utils/env-helper';

const Step = {
  props: ['currentStep', 'step', 'label'],
  template: `<li :class="{ active: currentStep === step, completed: currentStep > step }">
    <router-link to="">
      <b-icon icon="check"></b-icon>
      <span class="n">{{ step }}</span>
      <span class="text">{{ $t(label) }}</span>
    </router-link>
  </li>`,
};

@Component({
  components: {
    Step,
  },
})
export default class OnboardingSteps extends Vue {
  @Prop({ default: 0 }) currentStep?: number;

  get pCurrentStep() {
    return this.currentStep;
  }

  // eslint-disable-next-line class-methods-use-this
  get steps() {
    const arr = ['onboarding.steps.step1'];

    if (!skipIdentityVerification()) {
      arr.push('onboarding.steps.step2');
    }

    arr.push('onboarding.steps.step3');

    return arr;
  }
}
