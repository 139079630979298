




















































import { Component } from 'vue-property-decorator';

import { ForgotPasswordRequest } from '@/types';
import { actions } from '@/store';
import {
  required,
} from '../utils/validations';

import BaseComponent from './base-component';

const { FORGOT_PASSWORD_REQUEST } = actions;

@Component
export default class ForgotPassword extends BaseComponent {
  validators = {
    email: [
      required(this.$t('required.field', [this.$t('email')])),
    ],
  };

  submit(values: any): void {
    if (this.snackbarRef) {
      this.snackbarRef.close();
    }

    const payload: ForgotPasswordRequest = {
      email: values.email.trim().toLowerCase(),
    };

    this.$store.dispatch(FORGOT_PASSWORD_REQUEST, payload)
      .then(() => {
        this.$router.replace('/forgot-password-instructions');
      })
      .catch((err) => {
        this.handleError(err, 'form.forgotPassword.error.title');
      });
  }

  navigateBack() {
    this.$router.back();
  }
}
