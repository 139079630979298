











import { Component } from 'vue-property-decorator';
import FieldComponent from './FieldComponent';

@Component
export default class Field extends FieldComponent {}
