


















import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

import {
  Workflow,
} from '@/types';
import { actions } from '@/store';

@Component
export default class ViewTask extends BaseComponent {
  loading = false;

  get task() {
    return this.$store.state.task.groupedData[this.$route.params.id];
  }

  get hasGlobalMenu() {
    return this.task?.status !== 'completed';
  }

  get pBreadcrumbs() {
    const task = this.$store.state.task.groupedData[this.$route.params.id];

    const workflow = (task?.workflow as Workflow);

    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.workflows',
        path: '/workflows',
      },
      {
        title: workflow?.name || '...',
        path: `/workflows/${workflow?.id}`,
      },
      {
        title: 'dashboard.menu.tasks',
        path: '/tasks',
      },
      {
        title: task?.title,
      },
    ];
  }

  async mounted() {
    this.loading = true;
    try {
      const task = await this.$store.dispatch(actions.TASK_GET_BY_GROUP_ID, this.$route.params.id);
      if (task.user.id === this.$store.state.auth.identity.id) {
        task.user.lastName += ' (You)';
      }

      if (task.action.code === 'assigned_to_me') {
        task.contacts = [task.user];
      } else {
        task.title = task.action.name;
      }
    } catch (err) {
      this.handleError(err, 'error.generic');
    } finally {
      this.loading = false;
    }
  }

  actionHandler(action: string) {
    if (action === 'mark-as-done') {
      this.markAsDone();
    } else if (action === 'send-reminder') {
      this.sendReminder();
    }
  }

  markAsDone() {
    this.$store.dispatch('tasks/markAsDone', this.$route.params.id).then(() => {
      this.notify({
        message: 'Task completed successfully.',
        type: 'is-success',
      });
    });
  }

  sendReminder() {
    this.$store.dispatch('tasks/sendReminder', this.$route.params.id).then(() => {
      this.notify({
        message: 'Task reminder sent.',
        type: 'is-success',
      });
    });
  }
}
