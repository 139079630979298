import Vue from 'vue';
import { ActionTree } from 'vuex';
import ms from 'ms';

import {
  RegistrationRequest,
  AuthRequest,
  User,
  AuthResponse,
  Module,
  TwoFactorAuthRequest,
  TwoFactorAuthVerifyRequest,
  TwoFactorAuthResponse,
  TwoFAState,
} from '@/types';
import {
  Auth,
  TwoFactorAuth,
} from '@/api';
import storage from '@/storage';
import { RootState, AuthState } from '../states';

const getDefaultState = (): AuthState => ({
  registering: false,
  authenticating: false,
  verifyingEmail: false,
  sending2FACode: false,
  verifying2FACode: false,
  token: null,
  identity: null,
  loading: false,
  error: null,
});

const storeAuth = (data: AuthResponse) => {
  try {
    if (data.jwt) {
      storage.token = data.jwt;
    }

    if (data.user) {
      storage.user = data.user;
    }
  } catch (e) {
    // ignore error
  }
};

const actions: ActionTree<AuthState, RootState> = {
  async sync({ commit }: any): Promise<AuthResponse | any> {
    return new Promise<AuthResponse | any>((resolve) => {
      const { user: identity, token, userState } = storage;
      let data;

      if (identity || token) {
        data = { identity, token, twoFA: userState?.twoFA };
      }

      if (data) {
        commit('setState', data);
      }
      resolve(data);
    });
  },

  async register({ commit }: any, payload: RegistrationRequest): Promise<AuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AuthResponse | any>(async (resolve, reject) => {
      commit('setRegistering', {
        registering: true,
        auth: null,
        loading: true,
        error: null,
      });

      try {
        const resp = await Auth.register(payload);
        const { data } = resp;

        commit('setRegistering', {
          registering: false,
          token: data.jwt,
          identity: data.user,
          loading: false,
        });

        storeAuth(data);
        resolve(data);
      } catch (error) {
        commit('setRegistering', {
          registering: false,
          loading: false,
          error,
        });

        reject(error);
      }
    });
  },
  async authenticate({ commit, dispatch }: any,
    payload: AuthRequest): Promise<AuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AuthResponse | any>(async (resolve, reject) => {
      commit('setAuthenticating', {
        authenticating: true,
        auth: null,
        loading: true,
        error: null,
      });

      try {
        const resp = await Auth.authenticate(payload);
        const { data } = resp;

        storeAuth(data);

        commit('setAuthenticating', {
          authenticating: false,
          token: data.jwt,
          identity: data.user,
          loading: false,
        });

        dispatch('session/start', {}, { root: true });

        resolve(data);
      } catch (error) {
        commit('setAuthenticating', {
          authenticating: false,
          loading: false,
          error,
        });

        reject(error);
      }
    });
  },
  async verifyEmail({ commit }: any, token: string): Promise<AuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AuthResponse | any>(async (resolve, reject) => {
      commit('setVerifyEmail', {
        verifyingEmail: true,
        auth: null,
        loading: true,
        error: null,
      });

      try {
        const resp = await Auth.verifyEmail(token);
        const { data } = resp;

        storeAuth(data);

        commit('setVerifyEmail', {
          verifyingEmail: false,
          token: data.jwt,
          identity: data.user,
          loading: false,
        });

        resolve(data);
      } catch (error) {
        commit('setVerifyEmail', {
          verifyingEmail: false,
          loading: false,
          error,
        });

        reject(error);
      }
    });
  },

  /**
   * Api call to send an otp to the user's mobile phone
   * @param {commit} parameter to commit a mutation
   */
  async send2FACode({ commit, getters }: any): Promise<TwoFactorAuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<TwoFactorAuthResponse | any>(async (resolve, reject) => {
      commit('setSend2FACode', {
        sending2FACode: true,
        loading: true,
        error: null,
      });

      try {
        const payload: TwoFactorAuthRequest = {
          phoneNumber: getters.identity.mobile,
          channel: 'sms',
        };
        const resp = await TwoFactorAuth.send(payload);
        const { data } = resp;

        commit('setSend2FACode', {
          sending2FACode: false,
          loading: false,
        });

        resolve(data);
      } catch (error) {
        commit('setSend2FACode', {
          sending2FACode: false,
          loading: false,
          error,
        });

        reject(error);
      }
    });
  },

  /**
   * Api to verify sms code
   * @param {commit} parameter to commit a mutation
   * @param payload {mobile: '+91 999 999 9999', code: '123456'}
   */
  async verify2FACode({ commit }: any, payload: TwoFactorAuthVerifyRequest):
    Promise<AuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AuthResponse | any>(async (resolve, reject) => {
      commit('setVerify2FACode', {
        verifying2FACode: true,
        loading: true,
        error: null,
      });

      try {
        const resp = await TwoFactorAuth.verify(payload);
        const { data } = resp;

        if (data.jwt) {
          storeAuth(data);

          const twoFA: TwoFAState = {
            verifiedAt: Date.now(),
          };

          storage.userState = {
            twoFA,
          };

          commit('setTwoFactorAuth', twoFA);

          commit('setData', {
            token: data.jwt,
            identity: data.user,
          });
        }

        commit('setVerify2FACode', {
          verifying2FACode: false,
          loading: false,
        });

        resolve(data);
      } catch (error) {
        commit('setVerify2FACode', {
          verifying2FACode: false,
          loading: false,
          error,
        });

        reject(error);
      }
    });
  },
  async signOut({ commit, dispatch }: any):
    Promise<AuthResponse | any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AuthResponse | any>(async (resolve) => {
      dispatch('session/end', {}, { root: true });

      setTimeout(() => {
        storage.removeSessions();

        commit('clear');

        dispatch('clear', null, { root: true });

        resolve(true);
      });
    });
  },
};

const mutations = {
  setState(state: AuthState, { token, identity, twoFA }: AuthState) {
    state.token = token;
    state.identity = identity;
    // state.twoFA = twoFA;
    state.error = null;
    Vue.set(state, 'twoFA', twoFA);
  },

  setRegistering(state: AuthState, {
    registering,
    loading,
    token,
    identity,
    error,
  }: AuthState) {
    state.registering = registering;
    state.loading = loading;
    state.token = token;
    state.identity = identity;
    state.error = error;
  },

  setAuthenticating(state: AuthState, {
    authenticating,
    loading,
    token,
    identity,
    error,
  }: AuthState) {
    state.authenticating = authenticating;
    state.loading = loading;
    state.token = token;
    state.identity = identity;
    state.error = error;
  },

  setVerifyEmail(state: AuthState, {
    verifyingEmail,
    loading,
    token,
    identity,
    error,
  }: AuthState) {
    state.verifyingEmail = verifyingEmail;
    state.loading = loading;
    state.token = token;
    state.identity = identity;
    state.error = error;
  },

  setSend2FACode(state: AuthState, {
    sending2FACode,
    loading,
    error,
  }: AuthState) {
    state.sending2FACode = sending2FACode;
    state.loading = loading;
    state.error = error;
  },

  setVerify2FACode(state: AuthState, {
    verifying2FACode,
    loading,
    error,
  }: AuthState) {
    state.verifying2FACode = verifying2FACode;
    state.loading = loading;
    state.error = error;
  },

  setTwoFactorAuth(state: AuthState, twoFA: TwoFAState) {
    state.twoFA = twoFA;
  },

  setData(state: AuthState, {
    identity,
    token,
  }: AuthState) {
    state.identity = identity;
    state.token = token;
  },

  setIdentity(state: AuthState, identity: User) {
    state.identity = identity;
  },

  clear(state: AuthState) {
    state.token = null;
    state.identity = null;
    state.sending2FACode = false;
  },
  reset(state: AuthState) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  token: (state: AuthState): string | null | undefined => state.token,
  identity: (state: AuthState): User | null | undefined => state.identity,
  name: (state: AuthState): string => (state.identity ? `${state.identity.firstName} ${state.identity.lastName}` : ''),
  mobile: (state: AuthState): string | undefined => state.identity?.mobile,
  twoFactorAuth: (state: AuthState, _getters: any): boolean => {
    /*
    if (_getters.token) {
      try {
        const str = _getters.token.split('.')[1];
        const data = JSON.parse(atob(str));

        return !!data['2fa'];
      } catch (e) {
        // ignore
      }
    }

    return false;
    */

    const { twoFA } = state;

    if (twoFA) {
      const duration = ms(process.env.VUE_APP_2FA_VALIDITY)
                        || 1000 * 60 * 60 * 24; // 24h by default

      return Date.now() <= (twoFA.verifiedAt + duration);
    }

    return false;
  },
};

const state = (): AuthState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
