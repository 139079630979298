import { Workflow } from '@/types';
import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import BaseComponent from '../base-component';

@Component
export default class WorkflowActions extends BaseComponent {
  @Prop({ default: () => [] }) protected workflows?: Workflow[];

  protected get pWorkflows() {
    return this.workflows;
  }

  protected workflowName = '';

  protected onClickRename() {
    if (this.workflows && this.workflows.length) {
      (this.$refs.workflowRenameModal as any).openModal(this.workflows[0].name);
    }
  }

  protected get isCompleted() {
    if (this.pWorkflows && this.pWorkflows.length) {
      return this.pWorkflows[0].status === 'completed';
    }
    return false;
  }

  protected handleRename(workflowName: string) {
    if (this.workflows && this.workflows.length) {
      this.$store.dispatch(actions.WORKFLOW_UPDATE, {
        id: this.workflows[0].id,
        name: workflowName,
      }).then(() => {
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  protected handleDelete() {
    if (this.workflows && this.workflows.length) {
      const workflowName = this.workflows[0].name;
      this.$buefy.dialog.confirm({
        title: this.$t('screen.workflows.delete.title') as string,
        message: (this.$t('screen.workflows.delete.singleDelete') as string).replace('{workflowName}', workflowName),
        cancelText: this.$t('cancel') as string,
        confirmText: this.$t('delete') as string,
        onConfirm: () => {
          this.$buefy.toast.open({
            message: this.$t('screen.workflows.delete.deleting') as string,
            position: 'is-bottom',
          });
          this.$emit('onDeleteClick', this.workflows);
        },
      });
    }
  }
}
