


























































import { Workflow } from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class WorkflowActions extends BaseComponent {
  @Prop() workflow?: Workflow;

  get pWorkflows() {
    return this.workflow;
  }

  get isCompleted() {
    return this.pWorkflows?.status === 'completed';
  }

  onActionClick(e: Event, workflow: Workflow, action: string) {
    e.preventDefault();

    switch (action) {
      case 'invite-to-workflow':
        this.$router.push({ name: 'WorkflowInvite', query: { workflows: workflow.id } });
        break;
      case 'create-task':
        this.$router.push({ name: 'CreateTask', query: { workflow: workflow.id } });
        break;
      default:
        this.$emit('onActionClick', workflow, action);
    }
  }
}
