



















import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class TaskTabBar extends BaseComponent {
  @Prop({ default: 0 }) selectedIndex?: number;

  get pSelectedIndex() {
    return this.selectedIndex;
  }
}
