




























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class DateTime extends BaseComponent {
  @Prop({ default: () => false }) disabled?: boolean;

  @Prop() date?: Date;

  @Prop() error?: string;

  get pDisabled() {
    return this.disabled;
  }

  get pDate(): Date | undefined {
    return this.date;
  }

  set pDate(date: Date | undefined) {
    this.date = date;
  }

  get pError() {
    return this.error;
  }

  handleInput() {
    const date = this.pDate as Date;
    this.date = new Date(date.getTime() + this.businessCloseTime);
    this.$emit('input', this.date);
  }

  handleTimeInput() {
    this.$emit('input', this.date);
  }
}
