import { render, staticRenderFns } from "./ThirdPartTask.vue?vue&type=template&id=652c01c4&scoped=true&"
import script from "./ThirdPartTask.vue?vue&type=script&lang=ts&"
export * from "./ThirdPartTask.vue?vue&type=script&lang=ts&"
import style0 from "./ThirdPartTask.vue?vue&type=style&index=0&id=652c01c4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652c01c4",
  null
  
)

export default component.exports