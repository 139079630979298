





















import { actions } from '@/store';
import { Component, Prop } from 'vue-property-decorator';
import { Workflow } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class ContactWorkflows extends BaseComponent {
  dataFetched = false;

  @Prop() contact?: string;

  @Prop() status?: string;

  get pContact() {
    return this.contact;
  }

  get pWorkflows() {
    const ids = this.$store.state.searchContacts.workflows.data[this.contact as string] || [];
    return ids.map((id: string) => this.getWorkflowById(id));
  }

  getWorkflowById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  created() {
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      await this.$store.dispatch(
        actions.CONTACT_WORKFLOWS,
        {
          id: this.contact,
          query,
        },
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  onActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'edit':
        this.onClickEdit(workflow);
        break;
      case 'delete':
        this.onDeleteClick(workflow);
        break;
      default:
      // do nothing
    }
  }

  workflowName = '';

  workflow: Workflow | null = null;

  onClickEdit(workflow: Workflow) {
    this.workflow = workflow;
    this.workflowName = workflow.name;
    (this.$refs.workflowRenameModal as any).openModal();
  }

  handleRename(workflowName: string) {
    if (this.workflow) {
      this.$store.dispatch(actions.WORKFLOW_UPDATE, {
        id: this.workflow.id,
        name: workflowName,
      }).then(() => {
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  protected onDeleteClick(workflow: Workflow) {
    const workflowName = workflow.name;
    this.$buefy.dialog.confirm({
      title: this.$t('screen.workflows.delete.title') as string,
      message: (this.$t('screen.workflows.delete.singleDelete') as string).replace('{workflowName}', workflowName),
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('delete') as string,
      onConfirm: () => {
        this.$buefy.toast.open({
          message: this.$t('screen.workflows.delete.deleting') as string,
          position: 'is-bottom',
        });
        this.handleDelete(workflow);
      },
    });
  }

  protected async handleDelete(workflows: Workflow) {
    try {
      await this.$store.dispatch(actions.WORKFLOW_DELETE, workflows.id);

      this.notify({
        message: this.$t('screen.workflows.delete.success.message') as string,
      });

      await this.loadData();
    } catch (error) {
      this.handleError(error, 'screen.workflows.delete.error.title');
    }
  }
}
