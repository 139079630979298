import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { File, FileAttribute } from '@/types';
import prettyBytes from 'pretty-bytes';
import { actions } from '@/store';
import { fileUtils } from '@/utils';

@Component
export default class FileBrowser extends BaseComponent {
  @Prop({ default: false }) protected isGrid?: boolean;

  @Prop({ default: false }) protected showSelect?: boolean;

  @Prop({ default: true }) protected showContext?: boolean;

  @Prop({ default: false }) protected showOnlyWorkflows?: boolean;

  @Prop({ default: '' }) protected path?: string;

  @Prop({ default: 'name' }) protected sortBy?: string;

  @Prop({ default: () => [] }) protected selectable?: string[];

  @Prop({ default: false }) protected fetchRecent?: boolean;

  @Prop({ default: () => [] }) protected checkedIds?: string[];

  protected files: File[] = [];

  protected selected = '';

  protected get pSelectable() {
    return this.selectable;
  }

  protected get filesToShow() {
    return this.files.filter(
      (file: any) => (this.showOnlyWorkflows ? this.isWorkflowFolder(file) : true),
    );
  }

  protected getSize = (file: File) => {
    if ((file.size || 0) >= 0) {
      return prettyBytes(file.size || 0);
    }
    return '';
  };

  protected showFileActions(file: File): boolean {
    if (this.$route.name === 'FileManagerRecent') {
      return false;
    }
    if (this.$route.query.path && this.$store.state.fileManager.currentDir) {
      if (this.isWorkflowFolder(this.$store.state.fileManager.currentDir)) {
        return false;
      }
    }
    return !(this.isWorkflowFolder(file) || this.isFileLocked(file));
  }

  protected isWorkflowFolder = (file: File) => !!file.attributes.find(
    (attributes: FileAttribute) => attributes.name === 'type' && ['workflow', 'link'].includes(attributes.value),
  );

  protected isFileLink = (file: File) => file && file.attributes.find(
    (attributes: any) => attributes.name === 'type' && ['link'].includes(attributes.value),
  )

  protected isFileLocked = (file: File) => {
    if (file && file.lockInfo) {
      return file.lockInfo.locked;
    }
    return false;
  };

  protected getName = (file: File) => fileUtils.parseFileName(file)

  protected getNameLower = (file: File) => fileUtils.parseFileName(file).toLowerCase()

  protected getFilePath = (file: File) => fileUtils.parseFilePath(file)

  protected isFile = (file: File) => fileUtils.isFile(file)

  protected isFolder = (file: File) => fileUtils.isFolder(file)

  @Watch('checkedIds')
  protected emitChangeChecked() {
    this.$emit('changeChecked', this.checkedIds);
  }

  protected contextAction(id: string, action: string) {
    this.checkedIds = [id];
    this.emitChangeChecked();
    this.$emit('contextAction', action, this.$store.state.files.data[id]);
  }

  protected changePath(file: File) {
    this.$emit('changePath', file);
  }

  protected onFileSelect(file: File) {
    if (this.selectable && this.selectable.length) {
      this.selected = file.pathLower;
      this.$emit('onFileSelect', file);
    }
  }

  protected fetchFiles() {
    let action = actions.FILE_MANAGER_GET_LIST;
    if (this.fetchRecent) {
      action = actions.FILE_MANAGER_GET_RECENT;
    }
    this.$store.dispatch(action, this.path).then(() => {
      this.setFiles();
    }).catch((err) => {
      this.handleError(err, 'error.generic');
    });
  }

  @Watch('$store.state.fileManager.list')
  @Watch('$store.state.files.data')
  @Watch('sortBy')
  protected setFiles() {
    this.files = this.$store.state.fileManager.list.map(
      (id: string) => this.$store.state.files.data[id],
    );
    if (this.sortBy === 'name') {
      this.files.sort(this.sortByName);
    } else if (this.sortBy === 'name-rev') {
      this.files.sort(this.sortByNameRev);
    }
    this.files.sort(this.foldersFirst);
    this.files.sort(this.wfFolders);
    this.$emit('filesFetched', this.files);
  }

  protected wfFolders = (a: File, b: File) => {
    if (this.isWorkflowFolder(a) && !this.isWorkflowFolder(b)) {
      return -1;
    }
    if (!this.isWorkflowFolder(a) && this.isWorkflowFolder(b)) {
      return 1;
    }
    return 0;
  }

  protected sortByName = (a: File, b: File) => {
    if (this.getNameLower(a) < this.getNameLower(b)) {
      return -1;
    }
    if (this.getNameLower(a) > this.getNameLower(b)) {
      return 1;
    }
    return 0;
  }

  protected sortByNameRev = (a: File, b: File) => {
    if (this.getNameLower(a) > this.getNameLower(b)) {
      return -1;
    }
    if (this.getNameLower(a) < this.getNameLower(b)) {
      return 1;
    }
    return 0;
  }

  protected foldersFirst = (a: File, b: File) => {
    if (a.type === 'folder' && b.type === 'file') {
      return -1;
    }
    if (a.type === 'file' && b.type === 'folder') {
      return 1;
    }
    return 0;
  }

  @Watch('path')
  protected updateFiles() {
    this.fetchFiles();
    this.checkedIds = [];
  }

  protected handleDownloadClick = (e: Event) => {
    const element = e.currentTarget as Element;
    const path = element.getAttribute('path') as string;

    (element as any).href = fileUtils.buildDownloadUrl(path);
  }
}
