import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';

// import storage from '@/storage';

import { ui } from './modules/ui';
import { auth } from './modules/auth';
import { session } from './modules/session';
import { users } from './modules/users';
import { country } from './modules/country';
import { companyList } from './modules/company';
import { password } from './modules/password';
import { identityVerification } from './modules/identity-verification';
import { contactList } from './modules/contact-list';
import { searchContacts } from './modules/search-contacts';
import { contact } from './modules/contact';

import { workflows } from './modules/workflows';
import { workflowSearch } from './modules/workflow-search';
import { workflow } from './modules/workflow';
import { workflowContacts } from './modules/workflow-contacts';
import { workflowInvite } from './modules/workflow-invite';
import { workflowSummary } from './modules/workflow-summary';
import { task } from './modules/task';
import { taskSummary } from './modules/task-summary';
import { workflowDocuments } from './modules/workflow-documents';
import { templates } from './modules/template';
import { actions as _actions } from './modules/actions';
import { categories } from './modules/categories';
import { documentTypes } from './modules/document-types';
import { documentStatuses } from './modules/document-statuses';
import { thirdParty } from './modules/third-party';
import { files } from './modules/files';
import { fileManager } from './modules/file-manager';
import { tasks } from './modules/tasks';
import { eSign } from './modules/e-sign';
import { tags } from './modules/tags';
import { paymentGateway } from './modules/payment-gateway';
import { search } from './modules/search';

import actions from './constants';
import RootState from './states/root.state';

Vue.use(Vuex);

const isProduction = process.env.NODE_ENV === 'production';

const sync = async (context: ActionContext<RootState, RootState>) => {
  const authData = await context.dispatch(actions.SYNC_AUTH);

  return authData;
};

const clear = (context: ActionContext<RootState, RootState>) => {
  context.commit('actions/reset');
  context.commit('auth/reset');
  context.commit('categories/reset');
  context.commit('companyList/reset');
  context.commit('contact/reset');
  context.commit('contactList/reset');
  context.commit('country/reset');
  context.commit('documentStatuses/reset');
  context.commit('documentTypes/reset');
  context.commit('eSign/reset');
  context.commit('fileManager/reset');
  context.commit('files/reset');
  context.commit('identityVerification/reset');
  context.commit('password/reset');
  context.commit('paymentGateway/reset');
  context.commit('search/reset');
  context.commit('searchContacts/reset');
  context.commit('session/reset');
  context.commit('tags/reset');
  context.commit('task/reset');
  context.commit('taskSummary/reset');
  context.commit('tasks/reset');
  context.commit('templates/reset');
  context.commit('thirdParty/reset');
  context.commit('ui/reset');
  context.commit('users/reset');
  context.commit('workflow/reset');
  context.commit('workflowContacts/reset');
  context.commit('workflowDocuments/reset');
  context.commit('workflowInvite/reset');
  context.commit('workflowSearch/reset');
  context.commit('workflowSummary/reset');
  context.commit('workflows/reset');
};

/*
const initializeStore = (state: any) => {
  const authState = state.auth;
  const { user, token } = storage.sessionStorage;

  authState.identity = user;
  authState.token = token;
};
*/

// eslint-disable-next-line
const store: any = new Vuex.Store<RootState>({
  modules: {
    ui,
    auth,
    session,
    users,
    country,
    companyList,
    password,
    identityVerification,
    contactList,
    searchContacts,
    contact,
    workflows,
    workflowSearch,
    workflow,
    workflowContacts,
    workflowInvite,
    workflowSummary,
    task,
    taskSummary,
    workflowDocuments,
    templates,
    thirdParty,
    files,
    fileManager,
    actions: _actions,
    categories,
    documentTypes,
    documentStatuses,
    tasks,
    eSign,
    tags,
    paymentGateway,
    search,
  },
  /*
  mutations: {
    initializeStore,
  },
  */
  // plugins: isProduction ? [] : [createLogger()],
  actions: {
    sync,
    clear,
  },
  strict: !isProduction,
  devtools: !isProduction,
});

export default store;

export {
  actions,
};
