import qs from 'qs';

import storage from '@/storage';
import { File, FileAttribute } from '@/types';

export const buildDownloadUrl = (path: string) => {
  const baseUrl = process.env.VUE_APP_API_BASE_URL;
  const { token } = storage;

  const queryString = qs.stringify({
    'app-id': process.env.VUE_APP_API_KEY,
    'app-secret': process.env.VUE_APP_API_SECRET,
    token,
    path,
  });

  return `${baseUrl}/file-manager/download?${queryString}`;
};

export const buildThirdPartyDownloadUrl = (token: string, path: string) => {
  const baseUrl = process.env.VUE_APP_API_BASE_URL;

  const queryString = qs.stringify({
    'app-id': process.env.VUE_APP_API_KEY,
    'app-secret': process.env.VUE_APP_API_SECRET,
    path,
  });

  return `${baseUrl}/3p/${token}/file/download?${queryString}`;
};

export const parseFileName = (file: File) => {
  let name = file.attributes.find((attribute) => attribute.name === 'name')?.value;

  if (!name) {
    name = file.name;
  }

  return name;
};

export const parseFilePath = (file: File) => {
  let path = file.attributes.find((attribute) => attribute.name === 'target')?.value;

  if (!path) {
    path = file.pathLower;
  }

  return path;
};

export const isFile = (file: File) => file.type === 'file' || file.type === 'link';

export const isFolder = (file: File) => file.type === 'folder';

export const isWorkflowFolder = (file: File) => !!file.attributes.find(
  (attributes: FileAttribute) => attributes.name === 'type' && ['workflow', 'link'].includes(attributes.value),
);

export default {
  buildDownloadUrl,
  buildThirdPartyDownloadUrl,
  parseFileName,
  parseFilePath,
  isFile,
  isFolder,
  isWorkflowFolder,
};
