

















import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class FileUploadBox extends BaseComponent {
  files: File[] = [];

  @Watch('files')
  onFilesChanged(files: File[]) {
    console.log('files', files);
    this.$emit('input', files);
  }

  onFileAdded(files: File[]) {
    console.log('files:', files);
    this.$emit('input', files);
  }
}
