import Vue from 'vue';
import CookieLaw from 'vue-cookie-law';

import './input';
import './field';
import './headers';
import './dashboard';
import './contact';
import './document';
import './task';
import './workflow';
import './file-manager';
import './third-party';
import './subscriptions';
import './support';
import './invite';

import Logo from './Logo.vue';
import FormHeader from './FormHeader.vue';
import Footer from './Footer.vue';
import DefaultFooter from './DefaultFooter.vue';
import Modal from './Modal.vue';
import OnboardingSteps from './OnboardingSteps.vue';
import SignUp from './SignUp.vue';
import SignIn from './SignIn.vue';
import SidebarSignIn from './SidebarSignIn.vue';
import Country from './Country.vue';
import CompanyListDropdown from './CompanyListDropdown.vue';
import ContactsListDropdown from './ContactsListDropdown.vue';
import VerifyEmail from './VerifyEmail.vue';
import TwoFactorAuth from './2Fa.vue';
import SignUpInstructions from './SignUpInstructions.vue';
import ForgotPassword from './ForgotPassword.vue';
import ForgotPasswordInstructions from './ForgotPasswordInstructions.vue';
import ResetPassword from './ResetPassword.vue';
import PasswordPolicyInstructions from './PasswordPolicyInstructions.vue';
import Onfido from './Onfido.vue';
import TrialPeriod from './TrialPeriod.vue';
import DashboardSidebar from './DashboardSidebar.vue';
import Avatar from './Avatar.vue';
import Paginator from './Paginator.vue';
import Breadcrumb from './Breadcrumb.vue';
import QuickActionBarHeader from './QuickActionBarHeader.vue';
import SortDropdown from './SortDropdown.vue';
import WorkflowDropdown from './WorkflowDropdown.vue';
import MessageTemplate from './MessageTemplate.vue';
import Message from './Message.vue';
import FilesAdded from './FilesAdded.vue';
import Options from './Options.vue';
import ChatThread from './ChatThread.vue';
import GlobalFileActionsModal from './GlobalFileActionsModal.vue';
import FileComponent from './FileComponent.vue';
import SearchResult from './SearchResult.vue';

Vue.component('CookieLaw', CookieLaw);
Vue.component('Logo', Logo);
Vue.component('form-header', FormHeader);
Vue.component('Footer', Footer);
Vue.component('DefaultFooter', DefaultFooter);
Vue.component('Modal', Modal);
Vue.component('OnboardingSteps', OnboardingSteps);
Vue.component('SignUp', SignUp);
Vue.component('SignIn', SignIn);
Vue.component('SidebarSignIn', SidebarSignIn);
Vue.component('Country', Country);
Vue.component('CompanyListDropdown', CompanyListDropdown);
Vue.component('ContactsListDropdown', ContactsListDropdown);
Vue.component('VerifyEmail', VerifyEmail);
Vue.component('TwoFactorAuth', TwoFactorAuth);
Vue.component('SignUpInstructions', SignUpInstructions);
Vue.component('ForgotPassword', ForgotPassword);
Vue.component('ForgotPasswordInstructions', ForgotPasswordInstructions);
Vue.component('ResetPassword', ResetPassword);
Vue.component('PasswordPolicyInstructions', PasswordPolicyInstructions);
Vue.component('Onfido', Onfido);
Vue.component('TrialPeriod', TrialPeriod);
Vue.component('DashboardSidebar', DashboardSidebar);
Vue.component('Avatar', Avatar);
Vue.component('Paginator', Paginator);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('QuickActionBarHeader', QuickActionBarHeader);
Vue.component('SortDropdown', SortDropdown);
Vue.component('WorkflowDropdown', WorkflowDropdown);
Vue.component('Message', Message);
Vue.component('MessageTemplate', MessageTemplate);
Vue.component('FilesAdded', FilesAdded);
Vue.component('Options', Options);
Vue.component('ChatThread', ChatThread);
Vue.component('GlobalFileActionsModal', GlobalFileActionsModal);
Vue.component('FileComponent', FileComponent);
Vue.component('SearchResult', SearchResult);
