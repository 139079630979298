




























import Contact from './Contact';

export default class ViewContactWorkflows extends Contact {}
