

























































import { Component } from 'vue-property-decorator';

import {
  BreadcrumbItem,
} from '@/types';
import BaseComponent from '@/components/base-component';

@Component
export default class Support extends BaseComponent {
  isVideoVisible = false;

  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.support',
    },
  ];

  navigateBack() {
    this.$router.back();
  }

  toggleContact() {
    this.isVideoVisible = false;
  }

  toggleVideo() {
    this.isVideoVisible = true;
  }

  mounted() {
    if (this.$route.query.showVideo) {
      this.toggleVideo();
    }
  }
}
