






















































































import { Component } from 'vue-property-decorator';
import FileManagerComponent from '@/components/file-manager/file-manager';

@Component
export default class FileManagerWorkflows extends FileManagerComponent {}

