


























import { Component, Prop } from 'vue-property-decorator';

import {
  Comment,
  User,
  Contact,
} from '@/types';

import BaseComponent from './base-component';

@Component
export default class WorkflowDocumentDetail extends BaseComponent {
  @Prop({ default: 'user' }) ownerType?: string;

  @Prop() user?: User;

  @Prop() contact?: Contact;

  @Prop({ default: () => [] }) comments?: Comment[];

  get pOwnerType() {
    return this.ownerType;
  }

  get pUser() {
    return this.user;
  }

  get pContact() {
    return this.contact;
  }

  get pComments() {
    return this.comments;
  }

  getCommentOwner(comment: Comment) {
    let person;

    switch (comment.accountType) {
      case 'user':
        person = (this.user || comment.user) as User;
        break;
      default:
        person = (this.contact || comment.contact) as Contact;
    }

    return person;
  }

  parseComment = (comment: string) => comment.replace(/\r?\n/g, '<br/>')
}
