

















import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import { Country } from '@/types';

import BaseComponent from './base-component';

@Component
export default class Login extends BaseComponent {
  @Prop() protected validate?: Function | Array<Function>;

  get computed() {
    return {
      loading: this.$store.state.country.loading,
    };
  }

  created() {
    this.loadData();
  }

  async loadData() {
    const countries = await this.$store.dispatch(actions.COUNTRY_GET_ALL);

    const defaultSelected = countries.find((country: Country) => country.code === 'GB');

    if (defaultSelected) {
      (this.$refs.country as any).changeValue(defaultSelected, null);
    }
  }
}
