
































import cloneDeep from 'lodash/cloneDeep';
import { actions } from '@/store';
import { Task, defaultQuery } from '@/types';
import ms from 'ms';
import { Component, Emit } from 'vue-property-decorator';
import BaseComponent from '../base-component';

let queryWithFilters = cloneDeep(defaultQuery);

queryWithFilters = {
  ...queryWithFilters,
  filters: queryWithFilters.filters = {
    ...queryWithFilters.filters,
    _sort: 'dueDate:DESC',
    // eslint-disable-next-line @typescript-eslint/camelcase
    status_ne: 'completed',
  },
  pageSize: 5,
};

@Component
export default class extends BaseComponent {
  private query = queryWithFilters;

  dataFetched = false;

  tasks: string[] = [];

  tasksNotDue?: string[] = [];

  @Emit('onActionClick')
  // eslint-disable-next-line class-methods-use-this
  onActionClick(task: Task, action: string) {
    // ignore
  }

  onToggle(contact: Task, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }

  created() {
    this.loadData();
  }

  // eslint-disable-next-line class-methods-use-this
  showDueDateWarning(task: any) {
    const duration = ms(process.env.VUE_APP_TASK_OVERDUE_BY);
    return task.dueDate && Date.now() > (new Date(task.dueDate).getTime() - duration);
  }

  async loadData() {
    try {
      const tasksData = await this.$store.dispatch(actions.TASK_SUMMARY, this.query);
      this.tasks = tasksData.filter((taskValue: any) => this.showDueDateWarning(taskValue));
      this.tasksNotDue = tasksData.filter((taskValue: any) => !this.showDueDateWarning(taskValue));
    } catch (error) {
      // TODO: show error message
    }
    this.dataFetched = true;
  }
}
