import { AxiosResponse } from 'axios';

import {
  User,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static me(): Promise<AxiosResponse<User>> {
    return jwtApi.get<User>('/users/me');
  }
}
