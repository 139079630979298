import Vue from 'vue';
import { Plugin } from 'vue-fragment';
import VueMq from 'vue-mq';
import VueGtag from 'vue-gtag';

import '@/styles';
import store from '@/store';
import '@/components';
import i18n from '@/i18n';
import router from '@/router';
import '@/filters';

import App from './App.vue';
import './registerServiceWorker';

import './mixins';

Vue.use(Plugin);
Vue.use(VueMq);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
  pageTrackerTemplate(to: any) {
    return {
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_title: to.meta.title,
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_path: to.path,
    };
  },
}, router);

Vue.config.productionTip = true;

// if (process.env.NODE_ENV !== 'production') {
//   Vue.mixin({
//     created() {
//       console.log(`[created] ${(this as Vue).$options.name}`);
//     },
//   });
// }

new Vue({
  store,
  i18n,
  router,
  // beforeCreate() {
  //   (this as Vue).$store.commit('initializeStore');
  // },
  render: (h) => h(App),
}).$mount('#app');
