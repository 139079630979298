













































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { required } from '@/utils/validations';
import { CreateUpdateTaskPayload, Action } from '@/types';
import { actions } from '@/store';

@Component
export default class extends BaseComponent {
  @Prop() formHandler?: Function;

  @Prop() btnText?: string;

  validators = {
    workflow: [
      required(this.$t('required.field', [this.$t('Workflow')])),
    ],
    action: [
      required(this.$t('required.field', [this.$t('Action')])),
    ],
    title: [
      required(this.$t('required.field', [this.$t('Title')])),
    ],
  };

  formValues: {
    workflowId: string | null;
    actionId: string | null;
    contacts: {
      id: string;
      name: string;
    }[];
    hasDueDate: boolean;
    dueDate: Date | null;
    contactsErr: string;
    desc: string | null;
  } = {
    workflowId: null,
    actionId: null,
    contacts: [],
    hasDueDate: false,
    dueDate: null,
    contactsErr: '',
    desc: null,
  }

  files: File[] = [];

  alreadyAddedFiles: File[] = [];

  alreadyAddedContacts = [];

  handleFileUpload(file: File) {
    this.files.push(file);
  }

  contacts = [];

  filteredContacts = [];

  onDueDateChange(date: Date) {
    this.formValues.dueDate = date;
  }

  get workflows() {
    return this.$store.state.workflowSearch.data.map(
      (id: string) => this.$store.state.workflows.data[id],
    );
  }

  get actions() {
    const actns: Action[] = this.$store.getters['actions/list'];
    return actns.filter((action) => (
      action.applicableTo as number > 0
      && (action.applicableTo as number & 1) === 1
    ) || action.applicableTo === undefined);
  }

  @Watch('isAssignedToMe')
  initContacts() {
    if (this.isAssignedToMe) {
      (this.$refs.tagInput as any).newTag = '';
      this.formValues.contacts = [{
        id: 'me',
        name: `${this.$store.getters['auth/name']} (You)`,
      }];
    } else {
      this.formValues.contacts = [];
    }
  }

  getFilteredContacts(text: string | FocusEvent) {
    const contactIds = this.formValues.contacts.map((contact: any) => contact.id);
    this.filteredContacts = this.contacts.filter(
      (contact: any) => !contactIds.includes(contact.id),
    );
    const aaContactIds = this.alreadyAddedContacts.map((contact: any) => contact.id);
    if (aaContactIds.length) {
      this.filteredContacts = this.filteredContacts.filter(
        (contact: any) => !aaContactIds.includes(contact.id),
      );
    }
    if (typeof text === 'string') {
      this.filteredContacts = this.filteredContacts.filter((contact: any) => contact.name
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    }
  }

  handleSubmit(values: any) {
    this.formValues.contactsErr = '';
    const payload: CreateUpdateTaskPayload = {
      workflow: values.workflow,
      action: values.action,
      title: values.title,
    };

    if (!this.isAssignedToMe) {
      if (!this.alreadyAddedContacts.length && !this.formValues.contacts.length) {
        this.formValues.contactsErr = 'Contacts are required';
        return;
      }
      payload.contacts = this.formValues.contacts.map((contact: any) => contact.id);
    }

    if (this.formValues.hasDueDate) {
      if (!this.formValues.dueDate) {
        this.notify({
          message: 'Please select a due date.',
          type: 'is-danger',
        });
        return;
      }
      payload.dueDate = this.formValues.dueDate.toISOString();
    }

    if (this.formValues.desc) {
      payload.desc = this.formValues.desc;
    }
    if (this.files.length) {
      payload.files = this.files;
    }
    if (this.formHandler) {
      this.formHandler(payload);
    }
  }

  updateState(data: any) {
    if (data.values.workflow && data.values.workflow !== this.formValues.workflowId) {
      this.formValues.workflowId = data.values.workflow;
    }
    if (data.values.action && data.values.action !== this.formValues.actionId) {
      this.formValues.actionId = data.values.action;
    }
  }

  get isAssignedToMe() {
    if (this.formValues.actionId) {
      if (this.$store.state.actions.data[this.formValues.actionId]
        && this.$store.state.actions.data[this.formValues.actionId].code === 'assigned_to_me') {
        return true;
      }
    }
    return false;
  }

  @Watch('formValues.workflowId')
  async fetchContacts() {
    try {
      const res = await this.$store.dispatch(actions.WORKFLOW_SEARCH_CONTACTS, {
        id: this.formValues.workflowId,
        query: {
          filters: {},
        },
      });
      this.contacts = res.results.filter(
        (id: string) => this.$store.state.workflowContacts.data[id].status === 'accepted',
      ).map((id: string) => {
        const {
          id: cId,
          firstName,
          lastName,
        } = this.$store.state.workflowContacts.data[id].contact;
        return {
          id: cId,
          name: `${firstName} ${lastName}`,
        };
      });
      this.filteredContacts = [...this.contacts];
      if (!this.isAssignedToMe) {
        if (this.$route.query.contacts) {
          this.formValues.contacts = [];
          let contacts;
          if (typeof this.$route.query.contacts === 'string') {
            contacts = [this.$route.query.contacts];
          } else {
            contacts = this.$route.query.contacts;
          }
          contacts.forEach((contact) => {
            const fct = this.filteredContacts.find((fc: any) => fc.id === contact);
            if (fct) {
              this.formValues.contacts.push(fct);
            }
          });
        }
      }
    } catch (err) {
      this.handleError(err, 'error.generic');
    }
  }

  fetching = true;

  async mounted() {
    try {
      await this.$store.dispatch(actions.ACTIONS_LIST);
      await this.$store.dispatch(actions.WORKFLOWS_SEARCH, {});
      if (this.$route.query.workflow) {
        let workflowId;
        if (typeof this.$route.query.workflow === 'object') {
          [workflowId] = this.$route.query.workflow;
        } else {
          workflowId = this.$route.query.workflow;
        }
        (this.$refs.workflow as any).changeValue(workflowId, null);
      }
    } catch (err) {
      this.handleError(err, 'error.generic');
    } finally {
      this.fetching = false;
    }
  }

  async waitTillFetching() {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.fetching) {
          clearInterval(interval);
          resolve(0);
        }
      }, 500);
    });
  }

  async setTask(task: any) {
    await this.waitTillFetching();
    console.log(this.$refs.tagInput);
    (this.$refs.workflow as any).changeValue(task.workflow.id, null);
    (this.$refs.workflow as any).disable();
    (this.$refs.action as any).changeValue(task.action.id, null);
    if (task.action.code !== 'assigned_to_me') {
      (this.$refs.action as any).disable();
    }
    (this.$refs.title as any).changeValue(task.title, null);
    if (task.desc) {
      this.formValues.desc = task.desc;
    }
    if (task.dueDate) {
      this.formValues.hasDueDate = true;
      this.formValues.dueDate = new Date(task.dueDate);
    }
    this.alreadyAddedFiles = task.files;
    this.fetchContacts().then(() => {
      const contactIds = task.contacts.map((contact: any) => contact.id);
      this.alreadyAddedContacts = this.contacts.filter(
        (contact: any) => contactIds.includes(contact.id),
      );
    });
  }
}

