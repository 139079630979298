




























import { Task } from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class WorkflowActions extends BaseComponent {
  @Prop() task?: Task;

  get pTask() {
    return this.task;
  }

  onActionClick(e: Event, task: Task, action: string) {
    e.preventDefault();

    switch (action) {
      case 'edit':
        this.$router.push({ name: 'EditTask', params: { id: task.groupId as string } });
        break;
      default:
        this.$emit('onActionClick', task, action);
    }
  }
}
