import { ActionTree } from 'vuex';

import {
  CreateWorkflow,
  CustomError,
  Document,
  DocumentRequestPayload,
  Module,
  WorkflowDetails,
} from '@/types';
import {
  Workflow as api,
  FileManager as fileManagerApi,
} from '@/api';
import { RootState, WorkflowState } from '../states';

const getDefaultState = (): WorkflowState => ({
  data: '',
  loading: false,
  error: null,
});

const postRequest = async (context: any, data: DocumentRequestPayload) => {
  const payload: DocumentRequestPayload = {
    ...data,
    documents: [],
  };

  const documents = data.documents.filter((doc) => doc.selected);

  let uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];

  if (!uploadDocumentId) {
    await context.dispatch('actions/list', {}, { root: true });

    uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];
  }

  documents.forEach((doc) => {
    const {
      id,
      selected,
      hasDueDate,
      dueDate,
      document: documentId,
      documentName,
      showError,
      errors,
      ...rest
    } = doc;
    const document: any = rest;

    if (!(id as string).startsWith('temp_')) {
      document.id = id;
    }

    const otherId = context.rootGetters['categories/otherId'];

    if (doc.category === otherId) {
      document.documentName = documentName;
    } else {
      document.document = documentId;
    }

    if (hasDueDate && dueDate) {
      document.dueDate = new Date(dueDate).toISOString();
    }

    if (!doc.action) {
      document.action = uploadDocumentId;
    }

    payload.documents.push(document);
  });

  // eslint-disable-next-line no-restricted-syntax
  for await (const document of payload.documents) {
    if (document.files) {
      const files = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const file of document.files) {
        const f = await fileManagerApi.fileUpload(
          `/${data.workflow}`,
          file,
        );

        files.push(f.data);
      }

      document.files = files;
    }
  }

  return payload;
};

const getters = {};

const actions: ActionTree<WorkflowState, RootState> = {
  async getById({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getById(id);
        const { data } = resp;

        commit('setLoading', false);
        commit('setWorkflow', data.id);
        commit('workflows/setWorkflow', data, { root: true });

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async create({ commit }: any, payload: CreateWorkflow) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.create(payload);
        const { data } = resp;

        commit('setLoading', false);
        commit('setWorkflow', data.id);
        commit('workflows/setWorkflow', data, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async update({ commit }: any, payload: { id: string; name: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.update(payload.id, payload.name);
        const { data } = resp;

        commit('setLoading', false);
        commit('setWorkflow', data.id);
        commit('workflows/updateWorkflow', data, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async delete({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        await api.delete(id);

        commit('setLoading', false);
        commit('removeWorkflow');
        commit('workflowSummary/removeWorkflow', id, { root: true });
        commit('workflowSearch/removeWorkflow', id, { root: true });

        resolve(id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async documents({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Document[]>(async (resolve, reject) => {
      commit('workflows/setInitialDocumentsLoading', { id, loading: true }, { root: true });
      commit('workflows/setInitialDocumentsError', { id, error: null }, { root: true });

      try {
        const resp = await api.initialDocuments(id);
        const { data } = resp;

        const documents = data.map((document) => ({ ...document, id: `temp_${document.id}`, selected: true }));

        commit('workflows/setInitialDocumentsLoading', { id, loading: false }, { root: true });
        commit('workflows/setInitialDocuments', { id, documents }, { root: true });

        resolve(documents);
      } catch (error) {
        commit('workflows/setInitialDocumentsLoading', { id, loading: false }, { root: true });
        commit('workflows/setInitialDocumentsError', { id, error }, { root: true });

        reject(error);
      }
    });
  },

  async newDocumentsRequest(context: any, payload: DocumentRequestPayload) {
    const { commit } = context;
    const { workflow: id } = payload;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('workflows/setNewDocumentsSaving', { id, saving: true }, { root: true });
      commit('workflows/setInitialDocumentsError', { id, error: null }, { root: true });

      try {
        const updatedPayload = await postRequest(context, payload);
        await api.newDocumentsRequest(updatedPayload);

        commit('workflows/setNewDocumentsSaving', { id, saving: false }, { root: true });

        resolve();
      } catch (error) {
        commit('workflows/setNewDocumentsSaving', { id, saving: false }, { root: true });
        commit('workflows/setNewDocumentsError', { id, error }, { root: true });

        reject(error);
      }
    });
  },

  async multipleDetails(_: any, payload: string[]) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<WorkflowDetails[]>(async (resolve, reject) => {
      try {
        const res = await api.multipleDetails(payload);

        const { data } = res;

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  async deleteFile(_: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        await api.deleteFile(id);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: WorkflowState, loading: boolean) {
    state.loading = loading;
  },
  setWorkflow(state: WorkflowState, data: string) {
    state.data = data;
  },
  removeWorkflow(state: WorkflowState) {
    state.data = '';
  },
  setError(state: WorkflowState, error: CustomError) {
    state.error = error;
  },
  reset(state: WorkflowState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflow: Module<WorkflowState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
