




















































import { Component } from 'vue-property-decorator';

import { ResetPasswordRequest } from '@/types';
import { actions } from '@/store';
import {
  required,
  max,
  isStrongPassword,
} from '../utils/validations';

import BaseComponent from './base-component';

const { RESET_PASSWORD_REQUEST } = actions;

@Component
export default class Login extends BaseComponent {
  validators = {
    password: [
      required(this.$t('required.field', [this.$t('newPassword')])),
      isStrongPassword('Password must match the password policy'),
    ],
    confirmPassword: [
      required(this.$t('required.field', [this.$t('confirmPassword')])),
      max(20, this.$t('tooLong', [this.$t('password')])),
      this.passwordMismatched,
    ],
  };

  private passwordMismatched(value: any, values: any) {
    return value === values.password ? undefined : this.$t('form.signUp.error.passwordMismatched');
  }

  submit(values: any): void {
    if (this.snackbarRef) {
      this.snackbarRef.close();
    }

    const { code } = this.$route.query;

    const payload: ResetPasswordRequest = {
      code: code as string,
      password: values.password,
      passwordConfirmation: values.confirmPassword,
    };

    this.$store.dispatch(RESET_PASSWORD_REQUEST, payload)
      .then(() => {
        this.showLogin();
        this.$router.replace('/');
      })
      .catch((err) => {
        this.handleError(err, 'form.resetPassword.error.title');
      });
  }

  showLogin() {
    this.$store.dispatch(actions.LOGIN_SHOW);
  }
}
