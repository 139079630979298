import { AxiosResponse } from 'axios';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import logger from '../../utils/logger';

const processError = (response: AxiosResponse, message = null, stack = null) => {
  const data = {
    status: response.status,
    message: response?.data?.error || message,
    stack,
    detail: null,
  };

  if (response.data?.message) {
    if (isArray(response.data?.message) && response.data?.message[0]) {
      const { id: code, message: desc } = response.data.message[0].messages[0];
      data.detail = { code, desc } as any;
    } else if (isString(response.data?.message?.message)) {
      data.detail = {
        desc: response.data.message.message,
      } as any;
    } else if (isString(response.data?.message)) {
      data.detail = {
        desc: response.data.message,
      } as any;
    }
  }

  return data;
};

const handleError = (error: any) => {
  const data = processError(error.response, error.message, error.stack);

  logger.error(data);

  return Promise.reject(data);
};

export default handleError;
