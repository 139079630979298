







import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class ThirdPartyAssignedToBar extends BaseComponent {
  @Prop({ default: false }) loading?: boolean;

  @Prop({ default: 'ASSIGNED TO' }) label?: string;

  @Prop({ default: '' }) name?: string;

  get pLoading() {
    return this.loading;
  }

  get pLabel() {
    return this.label;
  }

  get pName() {
    return this.name;
  }
}
