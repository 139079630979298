



















































































import { Component, Watch } from 'vue-property-decorator';
import FileManagerComponent from '@/components/file-manager/file-manager';
import { actions } from '@/store';
import { BreadcrumbItem } from '@/types';

const breadcrumbs: BreadcrumbItem[] = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
  },
  {
    title: 'dashboard.menu.documents',
    path: '/file-manager',
  },
];

@Component
export default class FileManagerDocuments extends FileManagerComponent {
  breadcrumbs = breadcrumbs;

  get pBreadcrumbs() {
    return this.breadcrumbs;
  }

  @Watch('$store.state.fileManager.currentDir')
  getTitle() {
    if (!this.$route.query.path || typeof this.$route.query.path !== 'string') {
      return 'Documents';
    }

    if (this.$store.state.fileManager.currentDir
      && this.$route.query.path === this.$store.state.fileManager.currentDir.pathLower) {
      return this.getFolderName(this.$store.state.fileManager.currentDir);
    }

    return '';
  }

  @Watch('$route.query.path')
  updateDetails() {
    if (this.$route.query.path && typeof this.$route.query.path === 'string') {
      this.$store.dispatch(actions.FILE_MANAGER_GET_DETAILS, this.$route.query.path);
    }
  }

  mounted() {
    if (!this.$route.query.path || typeof this.$route.query.path !== 'string') {
      this.$router.replace({ name: 'FileManager' });
    } else {
      this.updateDetails();
    }
  }
}

