























































































import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';

@Component
export default class Dashboard extends BaseComponent {
  hasTitleBar = false;

  expanded = false;

  toggleTab = false;

  isImageModalActive = false;

  title = '';

  toggleSidebarMenu() {
    this.expanded = !this.expanded;
  }

  toggleTabs() {
    this.toggleTab = !this.toggleTab;
  }

  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '',
    },
  ]

  actionHandler(action: string) {
    if (action === 'create-folder') {
      (this.$refs.fileActions as any).onClickCreateMode();
    } else if (action === 'file-upload') {
      (this.$refs.fileActions as any).onClickUploadOnlyMode();
    }
  }
}
