























































import SignInBase from './SignInBase';

export default class SideIn extends SignInBase {}
