













































































import { Workflow } from '@/types';
import { Component } from 'vue-property-decorator';

import TaskListItem from './task-list-item';

@Component
export default class extends TaskListItem {
  get taskLink() {
    if (this.task?.assignedBy === 'contact' || (this.isBusiness && this.task?.assignedBy === 'user' && this.task?.assignedTo === 'contact')) {
      return {
        name: 'ViewWorkflowDocuments',
        params: {
          id: (this.task?.workflow as Workflow).id as string,
        },
      };
    }

    return {
      name: 'ViewTask',
      params: {
        id: this.task?.groupId as string,
      },
    };
  }

  saving = false;

  async markAsDone() {
    this.saving = true;
    try {
      await this.$store.dispatch('tasks/markAsDone', this.pTask?.groupId);
      this.isCompleted = true;
      this.notify({
        message: 'Task completed successfully.',
        type: 'is-success',
      });
    } catch (err) {
      this.handleError(err, 'error.generic');
    } finally {
      this.saving = false;
    }
  }
}
