import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { BreadcrumbItem, File, FileAttribute } from '@/types';
import { actions } from '@/store';
import { fileUtils } from '@/utils';

@Component
export default class FileManager extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.documents',
    },
  ];

  protected files: File[] = [];

  protected isGrid = false;

  protected checkedIds: string[] = [];

  protected sortBy = 'name';

  protected showSelect = false;

  protected changeLayout() {
    this.isGrid = !this.isGrid;
  }

  protected getFolderName = (file: File) => {
    if (file.attributes) {
      const name = file.attributes.find((attributes: FileAttribute) => attributes.name === 'name');
      if (name && name.value) {
        return name.value.toLowerCase();
      }
    }
    return file.name.toLowerCase();
  };

  protected clearSelect() {
    this.showSelect = false;
    this.checkedIds = [];
  }

  protected handleModalClose() {
    if (!this.showSelect) {
      this.checkedIds = [];
    }
  }

  protected actionHandler(action: any, file?: File) {
    const modalRef = this.$refs.fileManagerModal as any;
    if (action === 'upload') {
      if (this.$route.name === 'FileManager' && !file) {
        (this.$refs.globalFileActions as any).onClickUploadOnlyMode();
      } else {
        modalRef.onClickUpload(file?.pathLower);
      }
    } else if (action === 'add-folder') {
      modalRef.onClickAddFolder();
    } else if (action === 'rename') {
      modalRef.onClickRename(this.checkedIds);
    } else if (action === 'manage-tags') {
      if (this.checkedIds && this.checkedIds.length === 1) {
        this.$router.push({
          name: 'FileManagerTags',
          query: {
            path: fileUtils.parseFilePath(this.$store.state.files.data[this.checkedIds[0]]),
          },
        });
      }
    } else if (action === 'delete') {
      if (this.checkedIds.length) {
        const forDeleteNames = this.checkedIds.map(
          (id: string) => this.$store.state.files.data[id].name,
        );
        this.$buefy.dialog.confirm({
          title: 'Delete?',
          message: `Are you sure you want to delete these files/folders?
                    <br><b>${forDeleteNames.join('</b><br><b>')}</b>`,
          onConfirm: this.doDelete,
        });
      }
    }
  }

  protected doDelete() {
    if (this.checkedIds && this.checkedIds.length) {
      const entries = this.checkedIds.map(
        (id: string) => ({ path: this.$store.state.files.data[id].pathLower }),
      );
      this.$store.dispatch(actions.FILE_MANAGER_DELETE,
        { payload: { entries }, ids: this.checkedIds }).then(() => {
        this.notify({
          type: 'is-success',
          message: 'File deleted successfully.',
        });
        this.checkedIds = [];
      }).catch((err) => {
        this.handleError(err, 'error.generic');
      });
    }
  }

  protected changePath(file: File) {
    this.$router.push({ name: 'FileManagerDocuments', query: { path: file.pathLower } });
  }

  protected handleCheckedChange(checkedIds: string[]) {
    this.checkedIds = checkedIds;
  }

  protected handleRenameUI() {
    (this.$refs.fileBrowser as any).setFiles();
  }
}
