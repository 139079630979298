import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import { BreadcrumbItem, Workflow } from '@/types';
import Layout from '@/layouts/Layout';

const breadcrumbs: BreadcrumbItem[] = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
  },
  {
    title: 'dashboard.menu.workflows',
    path: '/workflows',
  },
];

@Component
export default class WorkflowComponent extends Layout {
  protected id?: string;

  protected workflow?: Workflow;

  @Prop({ default: '' }) emptyMessage?: string

  get pWorkflow(): Workflow {
    const workflow = this.$store.getters['workflows/getById'](this.id);

    return workflow || {
      id: this.id,
      name: '',
    };
  }

  get pEmptyMessage() {
    return this.emptyMessage;
  }

  get pBreadcrumbs() {
    let items = breadcrumbs;

    const workflow = this.$store.getters['workflows/getById'](this.id);

    if (workflow) {
      items = [
        ...items,
        {
          title: workflow.name,
        },
      ];
    }

    return items;
  }

  created() {
    const { id } = this.$route.params;

    this.id = id;

    if (id) {
      this.loadData();
    }
  }

  async loadData() {
    await this.$store.dispatch(actions.WORKFLOW_GET_BY_ID, this.id);

    this.workflow = this.$store.getters['workflows/getById'](this.id);

    this.workflowLoaded(this.workflow);
  }

  // eslint-disable-next-line class-methods-use-this
  workflowLoaded(workflow?: Workflow) {
    // let derived classes override it
  }

  protected workflowName = '';

  protected onClickEdit(workflow: Workflow) {
    this.workflow = workflow;
    (this.$refs.workflowRenameModal as any).openModal(workflow.name);
  }

  protected handleRename(workflowName: string) {
    if (this.workflow) {
      this.$store.dispatch(actions.WORKFLOW_UPDATE, {
        id: this.workflow.id,
        name: workflowName,
      }).then(() => {
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  protected handleDelete(workflow: Workflow) {
    const workflowName = workflow.name;
    this.$buefy.dialog.confirm({
      title: this.$t('screen.workflows.delete.title') as string,
      message: (this.$t('screen.workflows.delete.singleDelete') as string).replace('{workflowName}', workflowName),
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('delete') as string,
      onConfirm: () => {
        this.$buefy.toast.open({
          message: this.$t('screen.workflows.delete.deleting') as string,
          position: 'is-bottom',
        });
        this.onDeleteClick([workflow]);
      },
    });
  }

  protected async onDeleteClick(workflows: Workflow[]) {
    try {
      await this.$store.dispatch(actions.WORKFLOW_DELETE, workflows[0].id);

      this.notify({
        message: this.$t('screen.workflows.delete.success.message') as string,
      });

      if (this.$route.name?.startsWith('ViewWorkflow')) {
        await this.$router.replace({ name: 'Workflows' });
      }
    } catch (error) {
      this.handleError(error, 'screen.workflows.delete.error.title');
    }
  }
}
