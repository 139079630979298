

































import { mainMenu } from '@/utils/constants';
import { Component } from 'vue-property-decorator';

import { actions } from '@/store';
import BaseComponent from './base-component';

@Component
export default class DashboardSidebar extends BaseComponent {
  menuItems = mainMenu;

  toggleSidebar() {
    this.$store.dispatch(actions.TOGGLE_SIDEBAR);
  }

  collapseSidebar() {
    this.$store.dispatch(actions.COLLAPSE_SIDEBAR);
  }

  isSelected(path: string) {
    return this.$route.path === path || this.$route.path.startsWith(`${path}/`);
  }

  hideOnMobile() {
    if (this.$store.state.ui.sidebar.isMobile) {
      this.$store.dispatch(actions.COLLAPSE_SIDEBAR);
    }
  }
}
