























































































import { Component } from 'vue-property-decorator';

import WorkflowListItemBase from './workflow-list-item';

@Component
export default class WorkflowListItem extends WorkflowListItemBase {
  preventClick = (event: Event) => {
    event.stopPropagation();
  }
}
