











import BaseComponent from '@/components/base-component';
import { Component } from 'vue-property-decorator';

@Component({
})
export default class DefaultLayout extends BaseComponent {}
