















































import { Component } from 'vue-property-decorator';
import { File } from '@/types';
import { fileUtils } from '@/utils';
import { actions } from '@/store';
import Workflow from './Workflow';

@Component
export default class ViewWorkflowDocuments extends Workflow {
  activeTab = 0;

  dataLoaded = false;

  totalFiles = 0;

  onFilesFetched(files: object[]) {
    this.totalFiles = files.length;
    this.dataLoaded = true;
  }

  /*
  @Watch('activeTab')
  onTabChange(newTab: number) {
  }
  */
  actionHandler(action: any, file: File) {
    const modalRef = this.$refs.fileManagerModal as any;
    if (action === 'rename') {
      modalRef.onClickRename([file.id]);
    } else if (action === 'manage-tags') {
      this.$router.push({
        name: 'FileManagerTags',
        query: {
          path: fileUtils.parseFilePath(file),
        },
      });
    } else if (action === 'delete') {
      const forDeleteNames = [file.name];
      this.$buefy.dialog.confirm({
        title: 'Delete?',
        message: `Are you sure you want to delete these files/folders?
                  <br><b>${forDeleteNames.join('</b><br><b>')}</b>`,
        onConfirm: () => this.doDelete(file),
      });
    }
  }

  doDelete(file: File) {
    this.$store.dispatch(actions.FILE_MANAGER_DELETE,
      { payload: { entries: [{ path: file.pathLower }] }, ids: [file.id] }).then(() => {
      this.notify({
        type: 'is-success',
        message: 'File deleted successfully.',
      });
    }).catch((err) => {
      this.handleError(err, 'error.generic');
    });
  }

  handleRenameUI() {
    (this.$refs.fileBrowser as any).setFiles();
  }
}
