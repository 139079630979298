



























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class ViewTaskQuickActions extends BaseComponent {
  @Prop({ required: true }) task?: any;

  get showActions() {
    return this.task && this.task.user.id === this.$store.state.auth.identity.id
      && this.task.status === 'pending';
  }

  get isAssignedToMe() {
    return this.task && this.task.action.code === 'assigned_to_me';
  }

  markAsDone() {
    this.$emit('action', 'mark-as-done');
  }

  sendReminder() {
    this.$emit('action', 'send-reminder');
  }
}
