
























































import { Component, Prop } from 'vue-property-decorator';

import LayoutBase from './Layout';

@Component
export default class Layout extends LayoutBase {
  @Prop({ default: false }) expanded2?: boolean;

  get pExpanded2() {
    return this.expanded2;
  }

  toggleSidebarMenu() {
    this.expanded2 = !this.expanded2;
  }

  hideGlobalMenu() {
    this.expanded2 = false;
  }
}
