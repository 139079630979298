
































import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import BaseComponent from '@/components/base-component';
import { DocumentStatus } from '@/types';

@Component
export default class DocumentStatusList extends BaseComponent {
  @Prop() selected?: string | undefined;

  @Prop({ default: () => false }) disabled?: boolean;

  get pDisabled() {
    return this.disabled;
  }

  get pSelectedObject() {
    return this.$store.state.documentStatuses.data[this.selected as string];
  }

  get statuses() {
    return this.$store.getters['documentStatuses/list'];
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.$store.dispatch(actions.DOCUMENT_STATUS_LIST);
  }

  onChange(status: DocumentStatus) {
    // this.selected = status.id;
    this.$emit('change', status);
  }
}
