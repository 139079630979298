import { Workflow, WorkflowDetails } from '@/types';
import { Component, Emit, Prop } from 'vue-property-decorator';
import ms from 'ms';
import BaseComponent from '../base-component';

@Component
export default class WorkflowListItem extends BaseComponent {
  @Prop({ default: true }) hasActions?: boolean;

  @Prop() workflow?: Workflow;

  @Prop() contact?: string;

  @Prop({ default: false }) isThirdParty?: boolean;

  @Prop({ default: () => null }) details?: WorkflowDetails;

  get pHasActions() {
    return this.hasActions;
  }

  get pWorkflow() {
    return this.workflow;
  }

  get pIsThirdParty() {
    return this.isThirdParty;
  }

  get pDetails() {
    return this.details;
  }

  get detailContacts() {
    if (this.pDetails) {
      return this.pDetails.contacts.filter((contact) => contact.status === 'accepted');
    }

    return [];
  }

  @Emit('onActionClick')
  // eslint-disable-next-line class-methods-use-this
  onActionClick(workflow: Workflow, action: string) {
    // ignore
  }

  onToggle(checked: boolean) {
    this.$emit('onToggle', this.workflow, checked);
  }

  // eslint-disable-next-line class-methods-use-this
  calculatePercentage(workflow: Workflow) {
    if (workflow.completed && workflow.total) {
      return Math.round((workflow.completed / workflow.total) * 100);
    }
    return 0;
  }

  get owner() {
    return this.isThirdParty ? this.workflow?.contact : this.$store.state.auth.identity;
  }

  // eslint-disable-next-line class-methods-use-this
  showDueDateWarning(workflow: Workflow) {
    const duration = ms(process.env.VUE_APP_WORKFLOW_OVERDUE_BY);
    return workflow.status !== 'completed' && workflow.dueDate && Date.now() > (new Date(workflow.dueDate).getTime() - duration);
  }

  handleWorkflowClick() {
    if (this.isThirdParty) {
      if (this.workflow?.submit) {
        this.$router.push({
          name: 'AHDocumentRequestHistory',
          params: {
            token: this.workflow?.token as string,
          },
        });
      } else {
        this.$router.push({
          name: 'AHAcceptInvitation',
          params: {
            token: this.workflow?.token as string,
          },
        });
      }

      return;
    }

    if (this.contact || this.workflow?.contactCount) {
      this.$router.push({ name: 'ViewWorkflowTasks', params: { id: this.workflow?.id as string } });
      return;
    }

    this.$router.push({ name: 'ViewWorkflowContacts', params: { id: this.workflow?.id as string } });
  }
}
