





















import { Component } from 'vue-property-decorator';

import Workflow from './Workflow';

@Component
export default class ViewWorkflowMessages extends Workflow {}
