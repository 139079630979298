






































import { Component } from 'vue-property-decorator';

import {
  BreadcrumbItem,
} from '@/types';
import BaseComponent from '@/components/base-component';

@Component
export default class Reports extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.reports',
    },
  ];
}
