






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Message extends Vue {
  @Prop() template?: string;

  compiledTemplate: any = null;

  mounted() {
    this.compiledTemplate = Vue.compile(`<section>${this.template}</section>`);
  }
}
