






















































import { Component } from 'vue-property-decorator';

import Contact from './Contact';

@Component
export default class ViewContact extends Contact {}
