















































































import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { File, FileAttribute, Tag } from '@/types';
import { fileUtils } from '@/utils';

@Component
export default class FileManagerTags extends BaseComponent {
  file: File | null = null;

  tags: Tag[] = [];

  loading = false;

  allTags: Tag[] = [];

  filteredTags: Tag[] = [];

  getFileName = (file: File) => fileUtils.parseFileName(file)

  @Watch('tags')
  @Watch('$store.state.tags.all')
  setAllTags() {
    const tagIds = this.tags.filter((tag: Tag) => tag.id).map((tag) => tag.id);
    this.allTags = this.$store.state.tags.all.filter((tag: Tag) => !tagIds.includes(tag.id));
    this.filteredTags = this.allTags;
  }

  getFilteredTags(text: string) {
    this.filteredTags = this.allTags.filter((option: any) => option.name
      .toString().toLowerCase().indexOf(text.toLowerCase()) >= 0);
  }

  isWorkflowFolder = (file: File) => !!file.attributes.find(
    (attributes: FileAttribute) => attributes.name === 'type' && ['workflow', 'link'].includes(attributes.value),
  );

  async mounted() {
    if (!this.$route.query.path || !(typeof this.$route.query.path === 'string')) {
      this.$router.replace({ name: 'FileManager' });
    } else {
      try {
        this.loading = true;
        this.file = await this.$store.dispatch(actions.FILE_MANAGER_GET_DETAILS,
          this.$route.query.path);
        const tags = await this.$store.dispatch(actions.FILE_MANAGER_GET_TAGS, this.file?.id);
        this.tags = tags.map((tag: any) => ({
          id: tag.tag.id,
          name: tag.tag.name,
        }));
      } catch (err) {
        this.handleError(err, 'error.generic');
      } finally {
        this.loading = false;
      }
      await this.$store.dispatch(actions.TAGS_GET_ALL);
    }
  }

  async saveTags() {
    if (this.file) {
      this.addTagOnClick();
      if (!this.tags.length) {
        this.notify({
          message: 'Tags are required.',
          type: 'is-danger',
        });
        return;
      }
      try {
        await this.$store.dispatch(actions.FILE_MANAGER_ADD_TAGS, {
          id: this.file.id,
          path: this.file.pathLower,
          tags: this.tags.map((tag: Tag) => {
            if (tag.id) {
              return {
                id: tag.id,
              };
            }
            return {
              name: tag.name,
            };
          }),
        });
        this.$router.back();
      } catch (err) {
        this.handleError(err, 'error.generic');
      }
    }
  }

  addTag = (tag: string | Tag) => {
    if (typeof tag === 'string') {
      return {
        name: tag,
      };
    }
    return {
      id: tag.id,
      name: tag.name,
    };
  };

  addTagOnClick() {
    (this.$refs.tagInput as any).addTag();
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }

  checkIfTagExists(text: string) {
    if (text.length > 30) {
      this.notify({
        message: this.$t('screen.tags.error.size') as string,
        type: 'is-danger',
      });
      return false;
    }
    return !this.tags.some((tag) => tag.name?.toLowerCase() === text.toLowerCase());
  }
}
