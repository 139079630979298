



























import { Component } from 'vue-property-decorator';

import InputComponent from './input-component';

@Component
export default class Input extends InputComponent {}
