import { render, staticRenderFns } from "./DocumentTags.vue?vue&type=template&id=f0e99bee&scoped=true&"
var script = {}
import style0 from "./DocumentTags.vue?vue&type=style&index=0&id=f0e99bee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0e99bee",
  null
  
)

export default component.exports