































































































































































































import isArray from 'lodash/isArray';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import {
  Contact,
  Document,
  defaultQuery,
  Workflow,
  WorkflowContact,
  BreadcrumbItem,
} from '@/types';
import { actions } from '@/store';
import { debounce } from '@/utils';

import WorkflowComponent from './Workflow';

const searchDebounce = debounce(500);

Component.registerHooks([
  'beforeRouteLeave',
]);
@Component
export default class WorkflowInvite extends WorkflowComponent {
  private query = defaultQuery;

  dataFetched = false;

  step = 0;

  isDocumentChanged = false;

  isDocumentsValid = true;

  documents: Document[] = [];

  updatedDocuments: Document[] = [];

  @Prop() workflowContact?: string;

  @Prop({ default: () => Date.now() }) contactListKey?: number;

  @Prop({ default: () => [] }) contactIds?: string[];

  @Prop({ default: () => [] }) contacts?: string[];

  @Prop({ default: () => [] }) workflowId?: string | null | undefined;

  @Prop({ default: () => [] }) selected?: Contact[];

  @Prop() invitingContactId?: string | null;

  // eslint-disable-next-line class-methods-use-this
  async beforeRouteLeave(to: Route, from: Route, next: any) {
    this.conformation().then(() => {
      next();
      window.onbeforeunload = null;
    });
  }

  conformation() {
    return new Promise((resolve) => {
      if (!this.isDocumentChanged) {
        resolve();
        return;
      }
      this.$buefy.dialog.confirm({
        title: (this.$t('form.workflowInvite.cancel.confirmation.title') as string),
        message: (this.$t('form.workflowInvite.cancel.confirmation.message') as string),
        onConfirm: () => resolve(),
      });
    });
  }

  get invitingContact() {
    return this.pInvitingContactId ? this.$store.state
      .contactList?.data[this.pInvitingContactId] : null;
  }

  // eslint-disable-next-line class-methods-use-this
  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'screen.workflows.invite.title',
      },
    ];
  }

  get pContactListKey() {
    return this.contactListKey;
  }

  get loading() {
    return this.$store.state.searchContacts.loading;
  }

  get pData() {
    // Note: do not remove this line as it makes the proprty to rerender
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentId = this.$store.state.workflowInvite.data;
    const workflowId = this.workflow && this.workflow.id ? this.workflow.id : this.workflowId;
    const arr: WorkflowContact[] = [];

    const workflowContacts = this.getByWorkflowContacts(workflowId || '');

    if (this.contacts?.length) {
      (this.contacts || []).forEach((id) => {
        const contact = this.getContactById(id);
        let workflowContact = workflowContacts.find((wc) => (wc.contact as any)?.id === id);

        if (contact) {
          if (!workflowContact) {
            workflowContact = {
              id: '',
              workflow: '',
              status: '',
              acceptedAt: null,
              inviteCount: 0,
              contact,
            };
          }

          workflowContact.contact = contact;

          arr.push(workflowContact);
        }
      });
    }

    return arr;
  }

  get pWorkflowContact() {
    return this.workflowContact;
  }

  get pContactIds() {
    return this.contactIds;
  }

  get pWorkflowId() {
    return this.workflowId;
  }

  get pWorkflowName() {
    return this.pWorkflowId ? this.$store.state
      .workflows.data[this.pWorkflowId]?.name : '';
  }

  get pSelected() {
    return this.selected;
  }

  @Watch('step')
  onStepChange(step: number) {
    if (step === 0) {
      this.invitingContactId = null;
      this.updatedDocuments = [];
      this.isDocumentChanged = false;
      window.onbeforeunload = null;
      this.isDocumentsValid = true;
    } else if (step === 2) {
      this.isDocumentChanged = false;
      this.isDocumentsValid = false;
      window.onbeforeunload = null;
      this.$buefy.toast.open({
        message: this.$t('screen.workflows.contact.inviteSent') as string,
        position: 'is-bottom',
      });
    }
  }

  onWorkflowChange(workflow: Workflow) {
    this.workflow = workflow;
    this.loadWorkflowContacts(workflow.id as string);
  }

  getContactById(id: string) {
    return this.$store.getters['contactList/getById'](id);
  }

  get pInvitingContactId() {
    return this.invitingContactId;
  }

  get loadingDocuments() {
    const workflow = this.workflow && this.workflow.id ? this.workflow.id : this.workflowId;

    const documents = this.$store.state.workflows.documents || {};

    return documents[workflow as string]?.loading as boolean;
  }

  created() {
    const { contacts, workflows } = this.$route.query;

    if (isArray(contacts)) {
      this.contactIds = contacts as string[];
    } else if (contacts) {
      this.contactIds = [contacts];
    }

    this.workflowId = isArray(workflows) ? workflows[0] : workflows;

    if (this.workflowId) {
      this.loadWorkflowContacts(this.workflowId);
    }

    this.contacts = this.contactIds;

    if (this.contactIds?.length) {
      this.loadContacts();
    }
  }

  onDocumentChange(documents: Document[]) {
    if (this.updatedDocuments.length) {
      this.isDocumentChanged = true;
      window.onbeforeunload = () => this.$t('form.workflowInvite.cancel.confirmation.message') as string;
    }
    this.updatedDocuments = documents;
  }

  // mounted() {
  //   this.loadStaleData();
  //   this.loadData();
  // }

  onSearch(query: string) {
    const q = query.trim().toLowerCase();

    searchDebounce(() => this.loadData({
      filters: {
        _where: {
          _or: [
            // eslint-disable-next-line @typescript-eslint/camelcase
            { firstNameLower_contains: q },
            // eslint-disable-next-line @typescript-eslint/camelcase
            { lastNameLower_contains: q },
            // eslint-disable-next-line @typescript-eslint/camelcase
            { email_contains: q },
            {
              'company.nameLower_contains': q,
            },
          ],
        },
      },
    }));
  }

  async loadData(query = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
      };

      this.selected = [];

      const result = await this.$store.dispatch(
        actions.SEARCH_CONTACTS,
        this.query,
      );

      this.contacts = result.results;
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  async loadContacts() {
    try {
      await this.$store.dispatch(actions.CONTACT_MULTIPLE, this.contacts);
    } catch (error) {
      // TODO: show error message
    }
  }

  async loadWorkflowContacts(id: string) {
    try {
      await this.$store.dispatch(
        actions.WORKFLOW_SEARCH_CONTACTS,
        {
          id,
          query: {},
        },
      );
    } catch (error) {
      // TODO: show error message
    }
  }

  getByWorkflowContacts(id: string): WorkflowContact[] {
    const result = this.$store.getters['workflowContacts/getByWorkflow'](id)('all');

    return result.results.map((wc: string) => this.$store.getters['workflowContacts/getById'](wc));
  }

  async onBack() {
    this.conformation().then(() => {
      this.step = 0;
      window.onbeforeunload = null;
    });
  }

  async loadWorkflowDocuments() {
    const workflow = this.workflow && this.workflow.id ? this.workflow.id : this.workflowId;

    this.documents = await this.$store.dispatch('workflow/documents', workflow);

    if (!this.documents.length) {
      this.documents = [
        {
          id: `temp_${Date.now()}`,
          selected: true,
          category: '',
          document: '',
        } as any,
      ];
    }
  }

  validate() {
    if (!this.updatedDocuments.some((doc) => doc.selected)) {
      this.handleError({
        detail: {
          code: 'form.thirdParty.document.request.payload.empty.message',
        },
      }, 'form.thirdParty.document.request.error.title');
      return false;
    }

    let valid = true;

    const otherCategoryId = this.$store.getters['categories/otherId'];

    this.updatedDocuments.forEach((doc) => {
      if (doc.selected) {
        if (!this.validateDocument(doc, otherCategoryId)) {
          valid = false;
        }
      }
    });

    this.documents = this.updatedDocuments;

    return valid;
  }

  validateDocument = (doc: Document, otherCategoryId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    let valid = true;

    if (!doc.category) {
      valid = false;
      errors.category = 'Document Category is required';
    }

    if (doc.category === otherCategoryId && !doc.documentName) {
      valid = false;
      errors.documentName = 'Document Name is required';
    }

    if (doc.category !== otherCategoryId && !doc.document) {
      valid = false;
      errors.document = 'Document Type is required';
    }

    if (doc.hasDueDate && !doc.dueDate) {
      valid = false;
      errors.dueDate = 'Due Date is required';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const matchedDoc: any = doc;
    matchedDoc.errors = errors;
    matchedDoc.showError = true;

    return valid;
  }

  onInviteClick(workflowContact: WorkflowContact) {
    const workflow = this.workflow && this.workflow.id ? this.workflow.id : this.workflowId;

    if (!workflow) {
      this.$buefy.dialog.alert({
        title: this.$tc('form.workflowInvite.error.title', 1) as string,
        message: this.$t('form.workflowInvite.error.required.message') as string,
      });

      return;
    }

    this.invitingContactId = (workflowContact.contact as Contact).id;
    if (this.allowDocumentsWithInvite) {
      this.loadWorkflowDocuments();
      this.step = 1;
    } else {
      this.invite();
    }
  }

  async invite() {
    let loadingComponent;

    try {
      if (this.allowDocumentsWithInvite && !this.validate()) {
        this.isDocumentsValid = false;
        (this.$refs.workflowInvite as any).$el.scroll({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }

      const workflow = this.workflow && this.workflow.id ? this.workflow.id : this.workflowId;

      if (this.allowDocumentsWithInvite) {
        loadingComponent = this.$buefy.loading.open({
          isFullPage: true,
          canCancel: false,
        });
      }

      this.workflowContact = await this.$store.dispatch(actions.WORKFLOW_INVITE, {
        workflow,
        contact: this.invitingContactId,
        documents: this.updatedDocuments || [],
      });

      if (this.allowDocumentsWithInvite) {
        this.step = 2;
      } else {
        this.notify({
          message: this.$t('screen.workflows.contact.inviteSent') as string,
          type: 'is-success',
        });
      }
      // this.contactListKey = Date.now();
    } catch (error) {
      this.handleError(error, 'form.workflowInvite.error.title');
    }

    if (loadingComponent) {
      loadingComponent.close();
    }
  }

  clearIconClick() {
    this.contacts = [];
  }
}
