import Vue from 'vue';
import { ActionTree } from 'vuex';
import HelloSign from 'hellosign-embedded';

import {
  ESign as api,
} from '@/api';

import {
  ESign,
  ESignRequestPayload,
  CustomError,
  Module,
} from '@/types';

import { RootState, ESignState } from '../states';

interface StatusPayload {
  id: string;
  path?: string;
  status?: 'none' | 'generating-url' | 'signing' | 'signed';
  error?: CustomError;
}

const getDefaultState = (): ESignState => ({
  data: {},
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<ESignState, RootState> = {
  async sign({ commit }: any, payload: ESignRequestPayload) {
    commit('setLoading', true);

    commit('setStatus', {
      id: payload.id,
      status: 'generating-url',
    });

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<ESign>(async (resolve, reject) => {
      try {
        const resp = await api.getSignUrl('');
        const { data } = resp;

        const client = new HelloSign({
          clientId: process.env.HELLOSIGN_CLIENT_ID,
        });

        client.open(data.signingUrl);

        commit('setStatus', {
          id: payload.id,
          status: 'signing',
        });

        resolve(data);
      } catch (error) {
        commit('setStatus', {
          id: payload.id,
          status: 'none',
        });

        commit('setError', {
          id: payload.id,
          error,
        });

        reject(error);
      }

      commit('setLoading', false);
    });
  },
};

const mutations = {
  setLoading(state: ESignState, loading: boolean) {
    state.loading = loading;
  },
  setStatus(state: ESignState, payload: StatusPayload) {
    state.data[payload.id] = {
      id: payload.id,
      status: payload.status,
      error: null,
      loading: false,
    };
  },
  setError(state: ESignState, payload: StatusPayload) {
    state.data[payload.id] = {
      ...state.data[payload.id],
      error: payload.error,
    };
  },
  reset(state: ESignState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): ESignState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const eSign: Module<ESignState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
