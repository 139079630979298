












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { fileUtils } from '@/utils';

@Component
export default class FileComponent extends Vue {
  @Prop() file?: any;

  get name() {
    return fileUtils.parseFileName(this.file);
  }
}
