
























































































import { Component, Prop } from 'vue-property-decorator';

import { sortByDateDesc } from '@/utils/functions';

import { actions } from '@/store';
import {
  Contact,
  Document,
  DocumentStatus,
  DocumentType,
  ESign,
  ESignRequestPayload,
  SortItem,
  WorkflowFile,
} from '@/types';

import { helloSign } from '@/utils';

import BaseComponent from '../base-component';

const allRequests: any = {
  id: '',
  firstName: 'All',
  lastName: 'requests',
};

const allStatus: any = {
  id: '',
  name: 'All',
};

const sortItems: SortItem[] = [
  {
    key: 'dueDate',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'name-asc',
    label: 'Name asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'name-desc',
    label: 'Name desc',
    icon: 'sort-alphabetical-descending',
  },
];

@Component
export default class WorkflowDocumentRequests extends BaseComponent {
  dataFetched = false;

  selectedContact: Contact = allRequests;

  sortItems = sortItems;

  selectedSort = sortItems[0];

  selectedStatus = allStatus;

  // eSignModal = false;

  @Prop() workflow?: string;

  get hasDocuments() {
    return (this.$store.state.workflowDocuments.ids[this.workflow as string] || []).length;
  }

  get documents(): Document[] {
    const ids = this.$store.state.workflowDocuments.ids[this.workflow as string] || [];
    const data = this.$store.state.workflowDocuments.data[this.workflow as string] || {};

    let documents: Document[] = ids.map((id: string) => data[id]);

    if (this.selectedStatus.id) {
      documents = documents
        .filter((doc) => (doc.status as DocumentStatus).id === this.selectedStatus.id);
    }

    if (this.selectedContact.id) {
      documents = documents
        .filter((doc) => (doc.contact as Contact).id === this.selectedContact.id);
    }

    const items = documents.sort(this.sort);

    return items.map((item) => ({ ...item }));
  }

  get contacts(): Contact[] {
    const ids = this.$store.state.workflowDocuments.ids[this.workflow as string as string] || [];
    const data = this.$store.state.workflowDocuments.data[this.workflow as string] || {};
    const documents: Document[] = ids.map((id: string) => data[id]);
    const contacts = documents.map((doc: Document) => doc.contact as Contact);

    const obj: {
      [key: string]: Contact;
    } = {};

    contacts.forEach((contact: Contact) => {
      obj[contact.id as string] = contact;
    });

    return [
      allRequests,
      ...Object.keys(obj).map((key: string) => obj[key] as Contact),
    ];
  }

  get statuses(): DocumentStatus[] {
    const ids = this.$store.state.workflowDocuments.ids[this.workflow as string as string] || [];
    const data = this.$store.state.workflowDocuments.data[this.workflow as string] || {};
    const documents: Document[] = ids.map((id: string) => data[id]);
    const statuses = documents.map((doc: Document) => doc.status as DocumentStatus);

    const obj: {
      [key: string]: DocumentStatus;
    } = {};

    statuses.forEach((status: DocumentStatus) => {
      obj[status.id as string] = status;
    });

    return [
      allStatus,
      ...Object.keys(obj).map((key: string) => obj[key] as DocumentStatus),
    ];
  }

  get saving() {
    return this.$store.state.workflowDocuments.saving;
  }

  created() {
    this.loadData();
  }

  async loadData() {
    await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_LIST, { id: this.workflow });
    this.dataFetched = true;
  }

  changeContact(contact: Contact) {
    this.selectedContact = contact;
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;
  }

  changeStatus(status: DocumentStatus) {
    this.selectedStatus = status;
  }

  /*

  closeESIgnModal() {
    this.eSignModal = false;
  }

  onESignClick(document: Document) {
    this.eSignModal = true;
  }

  */

  async onESignClick(file: WorkflowFile) {
    try {
      const payload: ESignRequestPayload = {
        id: file.id as string,
      };

      const data: ESign = await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_ESIGN, payload);

      helloSign.client.open(data.signingUrl);

      // close will not work
      // helloSign.client.close = () => {
      //   setTimeout(() => {
      //     this.loadData();
      //   }, 2000);
      // };
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.eSign.error.title');
    }
  }

  sort(a: Document, b: Document) {
    switch (this.selectedSort.key) {
      case 'name-asc':
      case 'name-desc':
        return this.sortByName(this.selectedSort.key)(a, b);
      default:
        return sortByDateDesc(this.selectedSort.key)(a, b);
    }
  }

  sortByName = (key: string) => (a: Document, b: Document) => {
    const name1 = (a.documentName || (a.document as DocumentType).name).toLowerCase();
    const name2 = (b.documentName || (b.document as DocumentType).name).toLowerCase();

    if (name1 > name2) {
      return key === 'name-desc' ? -1 : 1;
    }

    if (name1 < name2) {
      return key === 'name-desc' ? 1 : -1;
    }

    return 0;
  }

  sortByDate = (key: string) => (a: Document, b: Document) => {
    const time = Number.MAX_VALUE;
    const date1 = a.dueDate ? new Date(a.dueDate).getTime() : time;
    const date2 = b.dueDate ? new Date(b.dueDate).getTime() : time;

    return date1 - date2;
  }

  async handleSendDocuments() {
    try {
      await this.$store
        .dispatch(actions.WORKFLOW_DOCUMENTS_SEND_DOCUMENTS, this.workflow as string);

      this.loadData();

      this.notify({
        message: this.$t('screen.workflows.document.request.save.success.message') as string,
        type: 'is-success',
      });
    } catch (error) {
      this.handleError(error, 'screen.workflows.document.request.save.error.title');
    }
  }
}
