





















































































import { Contact } from '@/types';
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class ContactListItem extends BaseComponent {
  @Prop({ default: false }) selectable?: boolean;

  @Prop({ default: false }) disabled?: boolean;

  @Prop() contact?: Contact;

  get pSelectable() {
    return this.selectable;
  }

  get pDisabled() {
    return this.disabled;
  }

  get pContact() {
    return this.contact;
  }

  onActionClick(e: Event, contact: Contact, action: string) {
    e.preventDefault();
    this.$emit('onActionClick', contact, action);
  }

  onToggle(checked: boolean) {
    this.$emit('onToggle', this.contact, checked);
  }
}
