













import { Contact, PaginatedResult, emptyPaginatedResult } from '@/types';
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class ContactList extends BaseComponent {
  @Prop({ default: false }) selectable?: boolean;

  get pSelectable() {
    return this.selectable;
  }

  @Prop({ default: emptyPaginatedResult })
  result?: PaginatedResult<Contact>;

  private get pResult(): PaginatedResult<Contact> | undefined {
    return this.result;
  }

  private set pResult(value: PaginatedResult<Contact>| undefined) {
    this.result = value;
  }

  @Emit('onActionClick')
  // eslint-disable-next-line class-methods-use-this
  onActionClick(contact: Contact, action: string) {
    // ignore
  }

  onToggle(contact: Contact, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }
}
